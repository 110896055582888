import Vue from 'vue'
import Vuex from 'vuex'

import questionsData from '@/assets/json/questions'
import resultsData from '@/assets/json/results'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // pages
    visibleStage: [],

    // check
    stage: 0,
    questions: questionsData,
    currentQuestion: null,
    prevQuestions: [],
    answers: [],
    patientData: [],

    results: resultsData,
    result: null,

    // calculator
    calculator: {
      age: null,
      wallThickness: null,
      leftAtrial: null,
      gradient: null,
      death: null,
      nonSustained: null,
      syncope: null,

      result: null
    }
  },
  getters: {
    getVisibleStage (state) {
      return state.visibleStage
    },
    getStage (state) {
      return state.stage
    },
    getQuestions (state) {
      return state.questions
    },
    getCurrentQuestion (state) {
      return state.currentQuestion
    },
    getPrevQuestions (state) {
      return state.prevQuestions
    },
    getAnswers (state) {
      return state.answers
    },
    getPatientData (state) {
      return state.patientData
    },
    getResult (state) {
      return state.result
    },
    getCalculatorAge (state) {
      return state.calculator.age
    },
    getCalculatorWallThickness (state) {
      return state.calculator.wallThickness
    },
    getCalculatorLeftAtrial (state) {
      return state.calculator.leftAtrial
    },
    getCalculatorGradient (state) {
      return state.calculator.gradient
    },
    getCalculatorDeath (state) {
      return state.calculator.death
    },
    getCalculatorNonSustained (state) {
      return state.calculator.nonSustained
    },
    getCalculatorSyncope (state) {
      return state.calculator.syncope
    },
    getCalculatorResult (state) {
      return state.calculator.result
    }
  },
  mutations: {
    setVisibleStage (state, value) {
      state.visibleStage.push(value)
    },
    setStage (state, value) {
      state.stage = value
    },
    setCurrentQuestion (state, value) {
      state.currentQuestion = value
    },
    setPrevQuestions (state, value) {
      state.prevQuestions = value
    },
    setAnswers (state, value) {
      state.answers = value
    },
    setPatientData (state, value) {
      // if (!state.patientData.includes(value)) {
      //   state.patientData.push(value)
      // }
      state.patientData = value
    },
    setResult (state, value) {
      state.result = value
    },
    setCalculatorAge (state, value) {
      state.calculator.age = value
    },
    setCalculatorWallThickness (state, value) {
      state.calculator.wallThickness = value
    },
    setCalculatorLeftAtrial (state, value) {
      state.calculator.leftAtrial = value
    },
    setCalculatorGradient (state, value) {
      state.calculator.gradient = value
    },
    setCalculatorDeath (state, value) {
      state.calculator.death = value
    },
    setCalculatorNonSustained (state, value) {
      state.calculator.nonSustained = value
    },
    setCalculatorSyncope (state, value) {
      state.calculator.syncope = value
    },
    setCalculatorResult (state, value) {
      state.calculator.result = value
    },
    setRestart (state) {
      state.visibleStage = []
      state.stage = 0
      state.prevQuestions = []
      state.answers = {}
      state.patientData = []
      state.result = null
      state.calculator = {
        age: null,
        wallThickness: null,
        leftAtrial: null,
        gradient: null,
        death: null,
        nonSustained: null,
        syncope: null,

        result: null
      }
    }
  },
  actions: {
    goRestart (context) {
      context.commit('setRestart')
    },
    goToQuestion (context, payload) {
      const nextQuestion = this.state.questions.find(item => item.id === payload)

      if (nextQuestion.id > this.state.currentQuestion.id) {
        context.commit('setPrevQuestions', [...this.state.prevQuestions, this.state.currentQuestion])
      } else {
        context.commit('setPrevQuestions', [...this.state.prevQuestions].filter(item => item.id !== nextQuestion.id))
      }

      context.commit('setCurrentQuestion', nextQuestion)
    },
    goToResult (context, payload) {
      const result = this.state.results.find(item => item.id === payload)

      context.commit('setPrevQuestions', [])
      context.commit('setCurrentQuestion', this.state.questions[0])
      context.commit('setResult', result)
    }
  }
})
