import Vue from 'vue'
import VueRouter from 'vue-router'
import Implantation from '../views/Implantation.vue'
import Risk from '../views/Risk.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Implantation',
    component: Implantation
  },
  {
    path: '/risk',
    name: 'Risk',
    component: Risk
  },
  {
    path: '/check',
    name: 'Check',
    component: () => import('../views/Check.vue')
  },
  {
    path: '/calculator',
    name: 'Calculator',
    component: () => import('../views/Calculator.vue')
  }
]

const router = new VueRouter({
  mode: 'history',

  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
