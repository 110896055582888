<template>
  <div class="header">
    <div class="header__top" :class="{withoutBorder: openMenu}">
      <a class="header__top--left" href="/">
        <img src="@/assets/img/logo.svg">
      </a>
      <div class="header__icon">
        <img v-if="!openMenu" @click="openMenu = !openMenu" src="@/assets/img/burger.svg">
        <img v-if="openMenu" @click="openMenu = !openMenu" src="@/assets/img/cross.svg">
      </div>
      <nav class="header__nav">
        <router-link onclick="gtag('event', 'click', {'event_category': 'меню: Імплантація дефібриляторів'})"
                     class="header__nav--item" to="/" :class="{ active: currentPage === 'implantation' }">Імплантація
          дефібриляторів
        </router-link>
        <router-link onclick="gtag('event', 'click', {'event_category': 'меню: Як оцінити ризик PCC'})"
                     class="header__nav--item" to="/risk" :class="{ active: currentPage === 'risk' }">Як оцінити ризик
          PCC
        </router-link>
        <router-link onclick="gtag('event', 'click', {'event_category': 'меню: Оцінити необхідність ІКД'})"
                     class="header__nav--item main-btn" to="/check">
          <div class="main-btn__text" @click="setRestart">
            Оцінити необхідність ІКД
          </div>
          <div class="after main-btn__decor">
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L8 8L1 15" stroke="#285EFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </router-link>
        <router-link onclick="gtag('event', 'click', {'event_category': 'меню: Калькулятор ризику РСС при ГКМП'})"
                     class="header__nav--item" to="/calculator" :class="{ active: currentPage === 'calculator' }">
          Калькулятор ризику<br> РСС при ГКМП
        </router-link>
      </nav>
    </div>
    <div v-if="openMenu" class="header__mobile-wrapper">
      <div class="header__mobile-menu mobile-menu" :class="{ openedSub: openSubmenu, risk: currentPage === 'risk' }">
        <nav class="mobile-menu__nav">
          <div v-if="currentPage === 'implantation'" class="mobile-menu__nav--top">
            <div class="mobile-menu__nav--bg">
              <div class="mobile-menu__nav--item current" @click="openSubmenu = !openSubmenu"
                   :class="{ opened: openSubmenu }">
                Імплантація дефібриляторів
                <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L8.5 8L16 1" stroke="white" stroke-width="2"/>
                </svg>
              </div>
              <div v-if="openSubmenu" class="mobile-menu__submenu">
                <a
                  v-for="item in navList"
                  :key="item.id"
                  :href="item.link"
                  class="mobile-menu__submenu--item"
                  @click.prevent="scrollTo(item.link)"
                  v-on:click="nextState(item.link, '')"
                >
                  {{ item.title }}
                </a>
              </div>
            </div>
            <router-link onclick="gtag('event', 'click', {'event_category': 'меню: Як оцінити ризик PCC'})"
                         class="mobile-menu__nav--item" to="/risk">
              Як оцінити ризик PCC
              <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.29289 9.0775C0.902369 9.46802 0.902369 10.1012 1.29289 10.4917C1.68342 10.8822 2.31658 10.8822 2.70711 10.4917L1.29289 9.0775ZM11.7846 1.00001C11.7846 0.447723 11.3369 6.2885e-06 10.7846 5.91873e-06L1.78463 -6.0632e-06C1.23234 -6.43297e-06 0.784625 0.447708 0.784624 0.999993C0.784624 1.55228 1.23234 1.99999 1.78462 1.99999L9.78462 2L9.78461 10C9.78461 10.5523 10.2323 11 10.7846 11C11.3369 11 11.7846 10.5523 11.7846 10L11.7846 1.00001ZM2.70711 10.4917L11.4917 1.70711L10.0775 0.292898L1.29289 9.0775L2.70711 10.4917Z"
                  fill="white"/>
              </svg>
            </router-link>

            <router-link onclick="gtag('event', 'click', {'event_category': 'меню: Оцінити необхідність ІКД'})"
                         class="mobile-menu__nav--item" to="/check">
              Оцінити необхідність ІКД
            </router-link>

            <router-link onclick="gtag('event', 'click', {'event_category': 'меню: Калькулятор ризику РСС при ГКМП'})"
                         class="mobile-menu__nav--item" to="/calculator">
              Калькулятор ризику РСС<br>при ГКМП
            </router-link>
          </div>
          <div v-if="currentPage === 'risk'" class="mobile-menu__nav--top">
            <router-link onclick="gtag('event', 'click', {'event_category': 'меню:  Імплантація дефібриляторів'})"
                         class="mobile-menu__nav--item" to="/">
              Імплантація дефібриляторів
              <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.29289 9.0775C0.902369 9.46802 0.902369 10.1012 1.29289 10.4917C1.68342 10.8822 2.31658 10.8822 2.70711 10.4917L1.29289 9.0775ZM11.7846 1.00001C11.7846 0.447723 11.3369 6.2885e-06 10.7846 5.91873e-06L1.78463 -6.0632e-06C1.23234 -6.43297e-06 0.784625 0.447708 0.784624 0.999993C0.784624 1.55228 1.23234 1.99999 1.78462 1.99999L9.78462 2L9.78461 10C9.78461 10.5523 10.2323 11 10.7846 11C11.3369 11 11.7846 10.5523 11.7846 10L11.7846 1.00001ZM2.70711 10.4917L11.4917 1.70711L10.0775 0.292898L1.29289 9.0775L2.70711 10.4917Z"
                  fill="white"/>
              </svg>
            </router-link>
            <div class="mobile-menu__nav--bg">
              <div class="mobile-menu__nav--item current" @click="openSubmenu = !openSubmenu"
                   :class="{ opened: openSubmenu }">
                Як оцінити ризик PCC
                <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L8.5 8L16 1" stroke="white" stroke-width="2"/>
                </svg>
              </div>
              <div v-if="openSubmenu" class="mobile-menu__submenu">
                <a :onclick="`gtag('event', 'click', {'event_category': 'меню: ' ${item.title})`"
                   v-for="item in navList"
                   :key="item.id"
                   :href="item.link"
                   class="mobile-menu__submenu--item"
                   @click.prevent="scrollTo(item.link)"
                   v-on:click="nextState(item.link, '')"
                >
                  {{ item.title }}
                </a>
              </div>
            </div>

            <router-link onclick="gtag('event', 'click', {'event_category': 'меню: Оцінити необхідність ІКД'})"
                         class="mobile-menu__nav--item" to="/check">
              Оцінити необхідність ІКД
            </router-link>

            <router-link onclick="gtag('event', 'click', {'event_category': 'меню: Калькулятор ризику РСС при ГКМП'})"
                         class="mobile-menu__nav--item" to="/calculator">
              Калькулятор ризику РСС<br>при ГКМП
            </router-link>
          </div>
          <div v-if="currentPage === 'check' || currentPage === 'calculator'" class="mobile-menu__nav--top">
<!--            <div class="mobile-menu__nav&#45;&#45;bg">-->
<!--              <div class="mobile-menu__nav&#45;&#45;item current" @click="openSubmenu = !openSubmenu"-->
<!--                   :class="{ opened: openSubmenu }">-->
<!--                Імплантація дефібриляторів-->
<!--                <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M1 1L8.5 8L16 1" stroke="white" stroke-width="2"/>-->
<!--                </svg>-->
<!--              </div>-->
<!--              <div v-if="openSubmenu" class="mobile-menu__submenu">-->
<!--                <a :onclick="`gtag('event', 'click', {'event_category': 'меню: ' ${item.title})`"-->
<!--                   v-for="item in navList"-->
<!--                   :key="item.id"-->
<!--                   :href="item.link"-->
<!--                   class="mobile-menu__submenu&#45;&#45;item"-->
<!--                   @click.prevent="scrollTo(item.link)"-->
<!--                   v-on:click="nextState(item.link, '')"-->
<!--                >-->
<!--                  {{ item.title }}-->
<!--                </a>-->
<!--              </div>-->
<!--            </div>-->

            <router-link style="margin-bottom: 30px;" class="mobile-menu__nav--item" to="/"
                         onclick="gtag('event', 'click', {'event_category': 'меню: Імплантація дефібриляторів'})">
              Імплантація дефібриляторів
              <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.29289 9.0775C0.902369 9.46802 0.902369 10.1012 1.29289 10.4917C1.68342 10.8822 2.31658 10.8822 2.70711 10.4917L1.29289 9.0775ZM11.7846 1.00001C11.7846 0.447723 11.3369 6.2885e-06 10.7846 5.91873e-06L1.78463 -6.0632e-06C1.23234 -6.43297e-06 0.784625 0.447708 0.784624 0.999993C0.784624 1.55228 1.23234 1.99999 1.78462 1.99999L9.78462 2L9.78461 10C9.78461 10.5523 10.2323 11 10.7846 11C11.3369 11 11.7846 10.5523 11.7846 10L11.7846 1.00001ZM2.70711 10.4917L11.4917 1.70711L10.0775 0.292898L1.29289 9.0775L2.70711 10.4917Z"
                  fill="white"/>
              </svg>
            </router-link>

            <router-link class="mobile-menu__nav--item" to="/risk"
                         onclick="gtag('event', 'click', {'event_category': 'меню: Як оцінити ризик PCC'})">
              Як оцінити ризик PCC
              <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.29289 9.0775C0.902369 9.46802 0.902369 10.1012 1.29289 10.4917C1.68342 10.8822 2.31658 10.8822 2.70711 10.4917L1.29289 9.0775ZM11.7846 1.00001C11.7846 0.447723 11.3369 6.2885e-06 10.7846 5.91873e-06L1.78463 -6.0632e-06C1.23234 -6.43297e-06 0.784625 0.447708 0.784624 0.999993C0.784624 1.55228 1.23234 1.99999 1.78462 1.99999L9.78462 2L9.78461 10C9.78461 10.5523 10.2323 11 10.7846 11C11.3369 11 11.7846 10.5523 11.7846 10L11.7846 1.00001ZM2.70711 10.4917L11.4917 1.70711L10.0775 0.292898L1.29289 9.0775L2.70711 10.4917Z"
                  fill="white"/>
              </svg>
            </router-link>

            <router-link class="mobile-menu__nav--item" to="/check"
                         onclick="gtag('event', 'click', {'event_category': 'меню: Оцінити необхідність ІКД'})">
              Оцінити необхідність ІКД
            </router-link>

            <router-link class="mobile-menu__nav--item" to="/calculator"
                         onclick="gtag('event', 'click', {'event_category': 'меню: Калькулятор ризику РСС при ГКМП'})">
              Калькулятор ризику РСС<br>при ГКМП
            </router-link>
          </div>
          <!--          <div v-if="currentPage === 'risk' || currentPage === 'implantation'" class="mobile-menu__bottom">-->
          <!--            <router-link class="main-btn" to="/check">-->
          <!--              <div class="main-btn__text"  @click="setRestart">-->
          <!--                Перевірити свого пацієнта-->
          <!--              </div>-->
          <!--              <div class="after main-btn__decor">-->
          <!--                <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
          <!--                  <path d="M1 1L8 8L1 15" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
          <!--                </svg>-->
          <!--              </div>-->
          <!--            </router-link>-->
          <!--          </div>-->
        </nav>
      </div>
    </div>
    <div v-if="currentPage !== 'check' && currentPage !== 'calculator'" class="header__bottom">
      <nav class="bottom-nav">

        <a :onclick="`gtag('event', 'click', {'event_category': 'меню: ${item.title}'})`"
           v-for="item in navList"
           :key="item.id"
           :href="item.link"
           v-on:click="nextState(item.link)"
           tabindex="-1"
           class="bottom-list__item"
           :data-text="item.title"
        >
          {{ item.title }}
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  name: 'Header',
  data: () => {
    return {
      openMenu: false,
      openSubmenu: false,
      implantationSubMenuItems: [
        {
          id: 1,
          title: 'Що таке ІКД?',
          link: '#ikd-1'
        },
        {
          id: 2,
          title: 'Актуальність проблеми',
          link: '#topicality'
        },
        {
          id: 3,
          title: 'Як працює ІКД?',
          link: '#how-works'
        },
        {
          id: 4,
          title: 'Функції ІКД?',
          link: '#function'
        },
        {
          id: 5,
          title: 'Показання',
          link: '#indication'
        },
        {
          id: 6,
          title: 'Алгоритм безоплатного отримання',
          link: '#conditions'
        }
      ],
      riskSubMenuItems: [
        {
          id: 1,
          title: 'Що таке РСС?',
          link: '#rrc'
        },
        {
          id: 2,
          title: 'Причини',
          link: '#main-reason'
        },
        {
          id: 3,
          title: 'Симптоми',
          link: '#symptoms'
        },
        {
          id: 4,
          title: 'Фактори ризику',
          link: '#risk-factors'
        },
        {
          id: 5,
          title: 'Стратифікація',
          link: '#stratification'
        },
        {
          id: 6,
          title: 'Методи профілактики',
          link: '#prevention'
        },
        {
          id: 7,
          title: 'Алгоритм перевірки',
          link: '#check-patient'
        }
      ]
    }
  },
  computed: {
    navList() {
      return this.currentPage === 'risk' ? this.riskSubMenuItems : this.implantationSubMenuItems
    }
  },
  props: {
    currentPage: {
      type: String,
      required: true
    },
    state: {
      type: String,
      required: false
    },
    currentState: {
      type: String,
      required: false
    }
  },
  methods: {
    ...mapMutations([
      'setRestart'
    ]),
    nextState(state) {
      this.$emit('changeState', state)
    },
    scrollTo(href) {
      this.openMenu = false
      const link = href.substring(1)
      const scrollTarget = document.getElementById(link)
      const elementPosition = scrollTarget.getBoundingClientRect().top

      const offsetPosition = elementPosition - 100
      window.scrollBy({
        top: offsetPosition,
        behavior: 'smooth'
      })
    }
  },
  mounted() {
    const index = window.location.href.toString().indexOf('#')
    if (index >= 0) {
      window.location.href = window.location.href.substring(index, 0)
    }

    document.querySelectorAll('.bottom-list__item').forEach(link => {
      link.addEventListener('click', function (e) {
        e.preventDefault()
        const href = this.getAttribute('href').substring(1)
        const scrollTarget = document.getElementById(href)
        const topOffset = document.querySelector('.header').offsetHeight
        const elementPosition = scrollTarget.getBoundingClientRect().top
        const offsetPosition = elementPosition - topOffset
        window.scrollBy({
          top: offsetPosition,
          behavior: 'smooth'
        })
      })
    })

    const link = document.querySelectorAll('.bottom-list__item')
    const headings = document.querySelectorAll('.anchor')

    document.addEventListener('scroll', (e) => {
      headings.forEach(ha => {
        const rect = ha.getBoundingClientRect()
        if (rect.top > 0 && rect.top < 450 && this.currentPage === 'implantation') {
          const location = window.location.toString().split('#')[0]
          history.replaceState(null, null, location + '#' + ha.id)
          this.nextState(ha.id)

          if (ha.id === 'ikd-1') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
            link[0].classList.add('active-link')
          } else if (ha.id === 'topicality' || ha.id === 'implantation-count') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
            link[1].classList.add('active-link')
          } else if (ha.id === 'how-works' || ha.id === 'composition') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
            link[2].classList.add('active-link')
          } else if (ha.id === 'function') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
            link[3].classList.add('active-link')
          } else if (ha.id === 'indication') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
            link[4].classList.add('active-link')
          } else if (ha.id === 'conditions') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
            link[5].classList.add('active-link')
          }
        }

        if (rect.top > 0 && rect.top < 450 && this.currentPage === 'risk') {
          const location = window.location.toString().split('#')[0]
          history.replaceState(null, null, location + '#' + ha.id)
          this.nextState(ha.id)

          if (ha.id === 'rrc' || ha.id === 'rrc-2') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
            link[0].classList.add('active-link')
          } else if (ha.id === 'main-reason') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
            link[1].classList.add('active-link')
          } else if (ha.id === 'symptoms') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
            link[2].classList.add('active-link')
          } else if (ha.id === 'risk-factors') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
            link[3].classList.add('active-link')
          } else if (ha.id === 'stratification') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
            link[4].classList.add('active-link')
          } else if (ha.id === 'prevention') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
            link[5].classList.add('active-link')
          } else if (ha.id === 'check-patient') {
            link.forEach((a) => {
              a.classList.remove('active-link')
            })
            link[6].classList.add('active-link')
          }
        }
      })
    })
  }
}
</script>

<style lang="scss" scoped>
$fontR: "Montserrat-Regular";
$fontM: "Montserrat-Medium";
$fontB: "Montserrat-Bold";
$fontSB: "Montserrat-SemiBold";

.main-btn {
  background: #285EFF;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 50px;
  width: 234px;
  //min-width: 234px;
  position: relative;
  z-index: 2;
  transition: all .5s ease-in-out;
  -webkit-tap-highlight-color: transparent !important;


  &__text {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    font-family: $fontM;
    text-align: left;
    max-width: 141px;
    position: relative;
    z-index: 2;
    transform: translateX(35px);
    transition: all .5s ease-in-out;
  }

  &__decor {
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    background-color: #FFFFFF;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto 0;

    svg {
      top: 6px;
      position: absolute;
      left: 11px;
    }

    &.after {
      transition: all .5s ease-in-out;
    }

  }

  &:hover {
    & > .main-btn__text {
      transform: translateX(60px);
      @media screen and (max-width: 540px) {
        transform: translateX(40px) !important;
      }
    }

    & > .main-btn__decor {
      &.after {
        right: calc(100% - 40px);

        @media screen and (max-width: 540px) {
          right: calc(100% - 29px);
        }
      }
    }
  }

  &:active {
    background-color: #1646D6;
  }
}

.header {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  max-width: 100vw;
  background: #FFFFFF;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 5%;
    border-bottom: 1px solid rgba(60, 60, 60, .5);

    &--left {
      -webkit-tap-highlight-color: transparent;

      img {
        -webkit-tap-highlight-color: transparent;
      }
    }

    &.withoutBorder {
      border-bottom: 1px solid transparent;
    }
  }

  &__nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &--item {
      margin-right: 37px;
      text-decoration: none;
      font-size: 16px;
      line-height: 20px;
      color: #3C3C3C;
      -webkit-tap-highlight-color: transparent !important;

      text-align: left;

      &:last-child {
        margin-right: 0;

        &.active {
          font-family: $fontB;
          font-weight: 700;
          position: relative;

          &::before {
            content: '';
            width: 5px;
            height: 5px;
            max-height: 5px;
            border: 1px solid #3C3C3C;
            border-radius: 50%;
            opacity: .5;
            bottom: -7px;
            left: calc(50% - 4px / 2);
            position: absolute;
          }

          &::after {
            content: '';
            width: 1px;
            background: #3C3C3C;
            height: 25px;
            max-height: 30px;
            opacity: .5;
            top: 46px;
            right: calc(50% - 2px);
            position: absolute;
          }
        }
      }

      &.active {
        font-family: $fontB;
        font-weight: 700;
        position: relative;

        &::before {
          content: '';
          width: 5px;
          height: 5px;
          max-height: 5px;
          border: 1px solid #3C3C3C;
          border-radius: 50%;
          opacity: .5;
          bottom: -12px;
          left: calc(50% - 5px / 2);
          position: absolute;
        }

        &::after {
          content: '';
          width: 1px;
          background: #3C3C3C;
          height: 30px;
          max-height: 30px;
          opacity: .5;
          top: 31px;
          right: calc(50% - 2px);
          position: absolute;
        }
      }
    }

    &.full {
      .header__nav {
        &--item {
          text-align: left;

          &:last-child {
            color: #285EFF;
            margin-right: 0;
          }

          &.main-btn {
            //min-width: 234px;

            & > .main-btn__text {
              max-width: 141px;
            }

            & > .main-btn__decor {
              transform: translateX(47px);
            }

            &:hover {
              & > .main-btn__text {
                transform: translateX(68px);
              }

              & > .main-btn__decor {
                &.after {
                  transform: translateX(-120px);
                }
              }

              &:active {
                background-color: #1646D6;
              }
            }
          }
        }
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    padding: 0 5%;
    height: 42px;
    // border-top: 1px solid #3C3C3C;
    border-bottom: 1px solid rgba(60, 60, 60, .5);
    @media screen and (max-width: 540px) {
      height: 29px;
    }
  }

  &__icon {
    display: none;
  }

  &__mobile-wrapper {
    height: calc(100vh - 75px);
    background-color: #FFFFFF;
  }

  &__mobile-menu {
    height: calc(100vh - 75px);
    background-color: #285EFF;
    background-image: url('../assets/img/implantation/heart-menu.png');
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 5;
    width: 100%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    padding: 0 20px;

    &.risk {
      background-color: #FB35A2;
    }
  }

  .mobile-menu {
    padding-top: 89px;
    transition-duration: .4s;
    transition-timing-function: ease-in;

    &.openedSub {
      padding-top: 46px;
    }

    &__nav {
      &--bg {
        background: rgba(255, 255, 255, 0.15);
        border-radius: 16px;
        padding: 12px 18px;
        margin-bottom: 30px;
        width: 100%;
      }

      &--item {
        color: #FFFFFF;
        font-size: 16px;
        line-height: 20px;
        font-family: $fontB;
        font-weight: 500;
        text-decoration: none;
        outline: none;
        -webkit-tap-highlight-color: transparent !important;
        margin-bottom: 30px;
        text-align: center;
        width: 100%;

        &:first-child {
          display: inline-block;
          //margin-top: 35px;
          margin-bottom: 0;
          margin-top: 0;
        }

        &.current {
          font-weight: 700;
          font-family: $fontB;
          position: relative;
          // margin-top: 35px;

          svg {
            transition-duration: .3s;
            margin-left: 8px;
          }

          &.opened {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }

      &--top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.check {
          .mobile-menu__nav--item {
            margin-top: 35px;
          }

          & > .main-btn {
            margin-top: 35px;
            background: #FFFFFF;
            width: 150px;
            height: 32px;

            & > .main-btn__text {
              color: #285EFF;
              font-size: 10px;
              transform: translateX(22px);
              width: 90px;
            }

            & > .main-btn__decor {
              background-color: #285EFF;
              transform: translateX(32px);
              width: 19px;
              height: 19px;

              svg {
                top: 1px;
                left: 6px;
                max-width: 6px;
              }
            }
          }
        }
      }
    }

    &.risk {
      .mobile-menu__nav--bg {
        margin-top: 30px;
      }

      .mobile-menu__nav--item.current {
        //margin-top: 35px;
      }
    }

    &__submenu {
      transition-duration: 1s;
      margin-bottom: 7px;

      &--item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 17px;
        font-size: 14px;
        line-height: 15px;
        color: #FFFFFF;
        text-decoration: none;
        -webkit-tap-highlight-color: transparent !important;
      }
    }

    &__bottom {
      position: absolute;
      bottom: 62px;
      left: calc(50% - 150px / 2);

      & > .main-btn {
        background: #FFFFFF;
        width: 150px;
        height: 32px;

        & > .main-btn__text {
          color: #285EFF;
          font-size: 10px;
          transform: translateX(22px);
          width: 80px;
        }

        & > .main-btn__decor {
          background-color: #285EFF;
          transform: translateX(36px);
          width: 19px;
          height: 19px;

          svg {
            top: 1px;
            left: 6px;
            max-width: 6px;
          }
        }
      }

      &:active {
        & > .main-btn {
          background: #DFE7FF;
        }

        .main-btn__decor {
          transform: translateX(36px);
        }

        .main-btn__text {
          transform: translateX(50px);
        }

        .main-btn__decor {
          &.after {
            transform: translateX(-68px);
          }
        }
      }
    }
  }
}

.bottom-nav {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  transition-duration: .6s;

  @media screen and (max-width: 1060px) {
    padding: 0;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    ::-moz-scrollbar {
      display: none;
    }

    /* ползунок скроллбара */
    ::-webkit-scrollbar-thumb {
      display: none;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: transparent;
    }
  }
  @media screen and (max-width: 810px) {
    //padding: 0 8px;
  }
}

.bottom-list {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 576px) {
    width: max-content;
  }

  & li:hover {
    cursor: pointer;
    font-family: $fontB;
    font-weight: 700;
  }

  &__item {
    padding: 0 8px;
    font-size: 16px;
    line-height: 19px;
    color: #3F4C56;
    text-decoration: none;
    white-space: nowrap;
    position: relative;
    transition-duration: .6s;
    margin-right: 5%;
    outline: none;

    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    -webkit-tap-highlight-color: transparent;

    &::before {
      content: attr(data-text) / "";
      height: 0;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-family: $fontB;
      font-weight: 700;
    }

    @media speech {
      display: none;
    }

    &:hover {
      cursor: pointer;
      font-family: $fontB;
      font-weight: 700;
      // text-shadow: 0 0 1px #3F4C56, 0 0 1px #3F4C56;
    }

    &.active-link {
      font-family: "Montserrat-Bold";
      font-weight: 700;
      outline: none;
      // text-shadow: 0 0 .9px #3F4C56, 0 0 .9px #3F4C56;
      &::after {
        content: '';
        width: inherit;
        height: 2px;
        background-color: #3C3C3C;
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
      }
    }

    @media screen and (max-width: 992px) {
      font-size: 14px;
      margin-right: 20px;
    }
    @media screen and (max-width: 540px) {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 1350px) {
  .bottom-list {
    &__item {
      min-width: fit-content;
      white-space: nowrap;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      &.active-link {
        &::after {
          bottom: -10px;
        }
      }
    }
  }
}

@media screen and (max-width: 1220px) {
  .header {
    &__nav {
      &.full {
        .header__nav {
          &--item {
            margin-right: 18px;
            text-align: left;
            font-size: 14px;

            &.main-btn {
              min-width: 184px;
              max-width: 200px;

              & > .main-btn__text {
                font-size: 14px;
                transform: translateX(22px);
              }

              & > .main-btn__decor {
                transform: translateX(16px);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1240px) {
  .header {
    &__top {
      padding: 20px;

      &--left {
        max-width: 103px;

        img {
          max-width: 100%;
          object-fit: contain;
        }
      }
    }

    &__bottom {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__nav {
      display: none;
    }

    &__icon {
      display: block;
    }
  }
}

@media screen and (max-width: 540px) {
  .bottom-list {
    &__item {
      padding: 0 10px;
      margin-right: 0px;

      &.active-link {
        &::after {
          bottom: -4px;
        }
      }
    }
  }
}

</style>
