<template>
  <div class="risk">
    <Header
      :state="state"
      :currentState="currentState"
      currentPage="risk"
      v-on:changeState="changeState($event)"
    >
    </Header>
    <TopSection currentPage="risk"></TopSection>
    <router-link to="/check" class="main-btn sticky"
                 onclick="gtag('event', 'click', {'event_category': 'Оцінити необхідність ІКД'})">
      <div class="main-btn__text" @click="setRestart">
        Оцінити необхідність ІКД
      </div>
      <div class="after main-btn__decor">
        <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L8 8L1 15" stroke="#285EFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </router-link>
    <section class="section-2" id="banner">
      <div class="section-2__left">
        <div class="section-2__container">
          <div class="section-2__icon-wrapper icon-wrapper rings"></div>
          <div class="section-2__icon-wrapper icon-wrapper rings"></div>
          <div class="section-2__icon-wrapper icon-wrapper rings"></div>
          <svg width="151" height="151" viewBox="0 0 151 151" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_501_17547)">
              <circle cx="75.5" cy="70.5" r="60.5" fill="#FB35A2"/>
            </g>
            <path
              d="M69.4111 100.792C68.8205 100.226 54.9795 86.8359 53.0043 84.8577C52.8703 84.7235 52.4038 84.3309 51.9572 83.9531C49.451 81.8507 44.2402 77.4719 40.6571 71.8158C35.9723 64.43 35.6695 57.2032 39.7787 50.9208C43.6446 45.0112 48.9597 41.7507 55.1532 41.4923L56.637 41.4326L61.838 59.4895H52.5775L68.8999 82.3527L64.6022 63.9628H73.4011L68.2945 49.6236L69.3813 48.5351C72.7758 45.1355 79.5251 41.4724 86.3191 41.4724C90.8798 41.4724 97.3859 43.1126 102.493 50.9159C106.602 57.1983 106.299 64.425 101.619 71.8108C98.036 77.4669 92.8252 81.8457 90.319 83.9481C89.8674 84.3259 89.4059 84.7136 89.2719 84.8527C87.2967 86.8309 73.4507 100.221 72.8651 100.787L71.1431 102.452L69.4111 100.792Z"
              stroke="white" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path
              d="M106.88 75.6634C115.272 65.7697 115.178 55.4902 109.461 46.7442C104.081 38.5226 96.1675 33.9913 87.1781 33.9913C81.6443 33.9913 76.2339 35.7722 71.8306 38.2209"
              stroke="white" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M45.1401 88.2643L65.232 107.612" stroke="white" stroke-width="4" stroke-miterlimit="10"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
              <filter id="filter0_d_501_17547" x="0" y="0" width="151" height="151" filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                               result="hardAlpha"/>
                <feOffset dy="5"/>
                <feGaussianBlur stdDeviation="7.5"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_501_17547"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_501_17547" result="shape"/>
              </filter>
            </defs>
          </svg>
        </div>
        <h3 class="section-2__subtitle risk__subtitle">Раптова серцева смерть (РСС) —</h3>
        <div class="section-2__text risk__text">
          це смерть у присутності свідків, яка виникла <strong>раптово впродовж 1 години з моменту появи
          симптомів</strong> захворювання або у разі, якщо особа за одну добу до цього була абсолютно
          здоровою<sup>1,2</sup>.
        </div>
      </div>
      <div class="section-2__right">
        <h3 class="section-2__subtitle risk__subtitle">До РСС не належать:</h3>
        <ul class="section-2__list">
          <li class="section-2__item"><strong>смерть від інфаркту міокарда (ІМ),</strong> що сталася більш ніж через 1
            годину від початку його виникнення
          </li>
          <li class="section-2__item"><strong>смерть від шоку та набряку легень</strong> оскільки в цих випадках її
            причиною є не захворювання, а зазначені ускладнення<sup>2</sup>.
          </li>
        </ul>
      </div>
    </section>
    <section class="section-3 anchor" id="main-reason">
      <h2 class="section-3__title">Основними причинами розвитку РСС у дорослого населення є:</h2>
      <div class="section-3__table">
        <div class="section-3__item">
          <div class="section-3__item--decor"></div>
          <div class="section-3__item--percent percent">до <span>90</span>%</div>
          <div class="section-3__item--text">ішемічна хвороба серця</div>
        </div>
        <div class="section-3__item">
          <div class="section-3__item--decor"></div>
          <div class="section-3__item--percent percent"><span>10-15</span>%</div>
          <div class="section-3__item--text">дилятаційна та гіпертрофічна кардіоміопатія</div>
        </div>
        <div class="section-3__item">
          <div class="section-3__item--decor"></div>
          <div class="section-3__item--percent percent"><span>5-10</span>%</div>
          <div class="section-3__item--text">аритмогенні розлади<sup>3</sup></div>
        </div>
      </div>
      <img class="section-3__chart" src="@/assets/img/risk/pie.png">
    </section>
    <section class="section-4">
      <div class="section-4__left">
        <div class="section-4__left--top">
          <div class="section-4__container">
            <div class="section-4__icon-wrapper icon-wrapper rings"></div>
            <div class="section-4__icon-wrapper icon-wrapper rings"></div>
            <div class="section-4__icon-wrapper icon-wrapper rings"></div>
            <svg width="151" height="151" viewBox="0 0 151 151" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_637_17547)">
                <circle cx="75.5" cy="70.5" r="60.5" fill="#FB35A2"/>
              </g>
              <path
                d="M76.0586 31C77.5988 31.1215 79.1759 31.0607 80.6606 31.3949C84.6897 32.294 87.2895 35.8721 87.0492 39.9302C86.4578 49.9964 85.8664 60.0626 85.275 70.1288C85.0963 73.1784 84.9053 76.2219 84.7082 79.2716C84.4371 83.5179 80.8763 86.8045 76.5576 86.9928C71.586 87.2054 68.29 84.1194 67.5754 80.6809C67.1688 78.737 67.1688 76.7079 67.0456 74.7093C66.639 68.1422 66.257 61.5691 65.8689 55.0021C65.567 49.9052 65.2344 44.8144 64.9879 39.7115C64.76 35.052 68.4933 31.2065 73.237 31.1154C74.1735 31.0972 75.116 31.1154 76.0525 31.1154C76.0525 31.079 76.0525 31.0364 76.0586 31ZM81.3445 59.0966H81.3383C81.7141 52.6815 82.0837 46.2603 82.478 39.8451C82.6259 37.403 81.0056 35.6413 78.529 35.623C76.7794 35.6109 75.0298 35.5987 73.274 35.6291C71.1055 35.6656 69.4236 37.4091 69.5407 39.5414C69.8179 44.6626 70.1321 49.7777 70.434 54.8989C70.8221 61.4173 71.2102 67.9357 71.5983 74.448C71.6907 75.9729 71.777 77.4977 71.8879 79.0164C72.0542 81.2338 73.8346 82.5642 76.4652 82.4609C78.4243 82.388 80.0076 80.93 80.1247 79.0103C80.5497 72.3765 80.944 65.7366 81.3445 59.0966Z"
                fill="white"/>
              <path
                d="M44.2389 59.6434C46.0317 59.6191 47.0482 61.4112 46.2288 63.0697C45.3047 64.9529 44.2759 66.8119 43.5859 68.7862C40.2468 78.3786 41.0723 87.6672 45.9516 96.5974C46.1302 96.9254 46.3397 97.2535 46.4444 97.6058C46.7771 98.6871 46.2288 99.8475 45.2123 100.303C44.1218 100.789 42.8343 100.437 42.2798 99.3189C41.1771 97.1077 39.9696 94.9085 39.181 92.5757C35.4785 81.653 36.5073 71.119 42.1443 61.0285C42.6803 60.0748 43.3394 59.6556 44.2389 59.6434Z"
                fill="white"/>
              <path
                d="M115 81.2584C114.951 86.8473 113.288 93.1835 109.905 99.0701C109.289 100.145 108.365 100.637 107.361 100.431C105.747 100.103 105.02 98.4748 105.858 96.9804C107.232 94.5504 108.365 92.0171 109.105 89.332C111.575 80.4079 110.583 71.8665 106.166 63.7078C105.95 63.313 105.698 62.9242 105.581 62.4989C105.273 61.4054 105.864 60.2512 106.887 59.8381C107.971 59.4007 109.154 59.7834 109.77 60.8344C111.316 63.4588 112.518 66.229 113.386 69.1449C114.458 72.7109 115.006 76.3923 115 81.2584Z"
                fill="white"/>
              <path
                d="M76.0157 108C71.4137 108 67.6372 104.282 67.6372 99.7378C67.6372 95.1998 71.4075 91.4819 76.0157 91.4819C80.6239 91.4819 84.388 95.1937 84.388 99.7378C84.3942 104.282 80.6239 108 76.0157 108ZM79.8168 99.7135C79.8045 97.6662 78.1041 95.9956 76.028 95.9895C73.9211 95.9835 72.2022 97.6845 72.2146 99.7621C72.2269 101.803 73.9334 103.48 76.0034 103.486C78.1103 103.498 79.8291 101.797 79.8168 99.7135Z"
                fill="white"/>
              <path
                d="M46.1548 80.0374C46.1979 74.9041 47.467 70.0684 50.0422 65.579C50.8061 64.2547 52.106 63.8052 53.295 64.4552C54.4717 65.0991 54.7612 66.4417 54.0343 67.7842C49.5863 75.9794 49.5863 84.1745 54.0404 92.3696C54.7674 93.706 54.4655 95.0547 53.2765 95.6926C52.0752 96.3365 50.7999 95.893 50.0298 94.5565C47.467 90.055 46.1979 85.2193 46.1548 80.0374Z"
                fill="white"/>
              <path
                d="M106.012 80.0428C105.827 85.243 104.552 90.1029 101.952 94.6105C101.391 95.5825 100.529 96.0503 99.5924 95.9167C97.9229 95.6797 97.085 94.0091 97.9229 92.5025C99.192 90.2305 100.165 87.8613 100.708 85.3159C102.007 79.2166 101.108 73.4393 98.1077 67.9658C97.929 67.6378 97.7381 67.2976 97.6518 66.9452C97.3992 65.8517 97.9783 64.7704 98.9825 64.3634C100.067 63.926 101.243 64.3208 101.866 65.3718C103.486 68.1177 104.656 71.0458 105.229 74.1683C105.593 76.1123 105.759 78.0867 106.012 80.0428Z"
                fill="white"/>
              <path
                d="M55.291 80.4141C55.3341 76.4593 56.2213 73.0938 58.0263 69.9652C58.7102 68.7806 59.9854 68.3675 61.1313 68.9446C62.2895 69.5339 62.7146 70.8825 62.0308 72.0975C60.6323 74.6004 59.8622 77.2551 59.8745 80.1164C59.8869 82.7347 60.5214 85.2072 61.7597 87.5218C61.9568 87.8924 62.1848 88.2751 62.2834 88.676C62.5483 89.7635 61.9753 90.8448 60.965 91.2579C59.9053 91.6953 58.7287 91.3429 58.1249 90.3345C56.6648 87.8924 55.7715 85.2558 55.4512 82.4431C55.3649 81.6534 55.328 80.8576 55.291 80.4141Z"
                fill="white"/>
              <path
                d="M96.7341 80.0671C96.6848 83.6392 95.8223 86.9925 94.0357 90.109C93.3334 91.3301 92.0212 91.7553 90.8691 91.1357C89.7232 90.5221 89.3597 89.1917 90.0313 87.9524C92.8775 82.6915 92.8775 77.4427 90.0313 72.1818C89.3597 70.9425 89.7294 69.6121 90.8753 68.9985C92.0211 68.385 93.3395 68.8163 94.0418 70.0313C95.8284 73.1477 96.6909 76.5011 96.7341 80.0671Z"
                fill="white"/>
              <defs>
                <filter id="filter0_d_637_17547" x="0" y="0" width="151" height="151" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                 result="hardAlpha"/>
                  <feOffset dy="5"/>
                  <feGaussianBlur stdDeviation="7.5"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_637_17547"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_637_17547" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
          <img class="section-4__img" src="@/assets/img/risk/icon_boy.svg">
        </div>
        <div class="section-4__left--bottom">Проте останнім часом РСС значно «помолодшала»<sup>2</sup></div>
      </div>
      <div class="section-4__right">
        <div class="section-4__description">
          У молодому (&#60;30 років) віці найбільш імовірними причинами розвитку раптової серцевої смерті є
        </div>
        <ul class="section-4__list">
          <li class="section-4__item">кардіоміопатії</li>
          <li class="section-4__item">коронарні аномалії</li>
          <li class="section-4__item">первинні аритмогенні розлади</li>
          <li class="section-4__item">зловживання наркотиками<sup>3</sup></li>
        </ul>
      </div>
    </section>
    <section class="section-5 anchor" id="symptoms">
      <div class="section-5__left">
        <img src="@/assets/img/risk/icon_lightning.svg">
        <div class="section-5__percent percent">у <span>50</span>% пацієнтів</div>
        <div class="section-5__text risk__text">розвиток раптової серцевої смерті може стати <strong>першим проявом
          серцевих захворювань.</strong></div>
      </div>
      <div class="section-5__right">
        <div class="section-5__subtitle risk__subtitle">Типові скарги пацієнтів перед розвитком РСС</div>
        <ul class="section-5__list">
          <li class="section-5__item">
            <div class="section-5__item--img-wrapper">
              <img src="@/assets/img/risk/icon_sick.svg">
            </div>
            <div class="risk__text section-5__text">загальне нездужання, задишка</div>
          </li>
          <li class="section-5__item">
            <div class="section-5__item--img-wrapper">
              <img src="@/assets/img/risk/icon_heart.svg">
            </div>
            <div class="risk__text section-5__text">стенокардія</div>
          </li>
          <li class="section-5__item">
            <div class="section-5__item--img-wrapper">
              <img src="@/assets/img/risk/icon_nud.svg">
            </div>
            <div class="risk__text section-5__text">нудота, блювання</div>
          </li>
          <li class="section-5__item">
            <div class="section-5__item--img-wrapper">
              <img src="@/assets/img/risk/icon_head.svg">
            </div>
            <div class="risk__text section-5__text">запаморочення, можлива втрата свідомості<sup>3</sup>.</div>
          </li>
        </ul>
      </div>
    </section>
    <section class="section-6 anchor" id="risk-factors">
      <div class="section-6__title risk__main-title">Фактори ризику РСС</div>
      <div class="section-6__description"><strong>На імовірність розвитку раптової серцевої смерті можуть впливати такі
        фактори,</strong> як вік, стать, активне або пасивне тютюнопаління, дисліпопротеїнемія, артеріальна гіпертензія,
        діабет, спадковість<sup>1</sup>.
      </div>
      <div class="section-6__subtitle">Основні фактори ризику РСС</div>
      <ul class="section-6__list">
        <li class="section-6__item">
          <div class="section-6__text risk__text"><strong>Стійка пароксизмальна шлуночкова тахікардія (ШТ) та зупинка
            серця в анамнезі</strong></div>
        </li>
        <li class="section-6__item">
          <div class="section-6__text risk__text"><strong>Поліморфна ШТ і шлуночкові екстрасистоли (ШЕ) класів
            ІІІ-V</strong></div>
        </li>
        <li class="section-6__item">
          <div class="section-6__text risk__text"><strong>Систолічна дисфункція лівого шлуночка (ЛШ) та СН</strong>
          </div>
        </li>
        <li class="section-6__item">
          <div class="section-6__text risk__text"><strong>Синкопальні стани кардіального ґенезу</strong></div>
        </li>
        <li class="section-6__item">
          <div class="section-6__text risk__text"><strong>Перенесений ІМ, коли ШТ та фібриляція шлуночків зустрічаються
            протягом року (5%) </strong></div>
        </li>
        <li class="section-6__item">
          <div class="section-6__text risk__text">Після перенесеного ІМ та при дисфункції лівого шлуночка <strong>основним
            індикатором підвищеного ризику РСС є фракція викиду лівого шлуночка 40% та менше<sup>1</sup>.</strong></div>
        </li>
      </ul>
    </section>
    <section class="section-7 anchor" id="stratification">
      <div class="section-7__title risk__main-title">Стратифікація ризику РСС</div>
      <ul class="section-7__list">
        <li class="section-7__item">
          <div class="section-7__description">З метою уточнення причин РСС доцільно використовувати такі методи
            діагностики<sup>2</sup>:
          </div>
        </li>
        <li class="section-7__item">
          <div class="section-7__item--img-wrapper">
            <img src="@/assets/img/risk/sec-7_icon-echo.svg">
          </div>
          <div class="section-7__item--text">
            <strong>ЕхоКГ та магнітно-резонансна томографія (МРТ)</strong>
          </div>
        </li>
        <li class="section-7__item">
          <div class="section-7__item--img-wrapper">
            <img src="@/assets/img/risk/sec-7_icon-ekg.svg">
          </div>
          <div class="section-7__item--text">
            <strong>ЕКГ</strong> з оцінкою морфології QRS, ST-T, QT
          </div>
        </li>
        <li class="section-7__item">
          <div class="section-7__item--img-wrapper">
            <img src="@/assets/img/risk/sec-7_icon-prock.svg">
          </div>
          <div class="section-7__item--text">
            <strong>Тест із прокаїнамідом/аймаліном/флекаїнідом</strong>
          </div>
        </li>
        <li class="section-7__item">
          <div class="section-7__item--img-wrapper">
            <img src="@/assets/img/risk/sec-7_icon-monit.svg">
          </div>
          <div class="section-7__item--text">
            <strong>Моніторинг ЕКГ за Холтером</strong> (ХМ ЕКГ) протягом 1-2 діб для оцінки інтервалу QT та зміни
            сегмента ST.
          </div>
        </li>
        <li class="section-7__item">
          <div class="section-7__item--img-wrapper">
            <img src="@/assets/img/risk/sec-7_icon-gen.svg">
          </div>
          <div class="section-7__item--text">
            <strong>Генетичні тести.</strong> Якщо є каналопатії та сімейна спадковість, визначаються генні фактори
          </div>
        </li>
        <li class="section-7__item">
          <div class="section-7__item--img-wrapper">
            <img src="@/assets/img/risk/sec-7_icon-angio.svg">
          </div>
          <div class="section-7__item--text">
            <strong>Коронарна ангіографія</strong> та електорофізіологічні дослідження (ЕФД)
          </div>
        </li>
        <li class="section-7__item">
          <div class="section-7__item--img-wrapper">
            <img src="@/assets/img/risk/sec-7_icon-stres.svg">
          </div>
          <div class="section-7__item--text">
            <strong>Стрес-тест<sup>2</sup></strong>
          </div>
        </li>
      </ul>
    </section>
    <section class="section-8 anchor" id="prevention">
      <div class="section-8__subtitle">Методи профілактики раптової серцевої смерті</div>
      <div class="section-8__description">Розрізняють первинну та вторинну профілактику.</div>
      <div class="section-8__middle">
        <div class="section-8__middle--left">
          <strong>Первинна профілактика РСС —</strong> це лікування, яке запобігає розвитку РСС у пацієнтів, які не мали
          раніше таких епізодів, але відносяться до групи високого ризику. Якщо у пацієнта такі випадки були, йдеться
          про вторинну профілактику РСС, адже існує висока вірогідність повторної зупинки серцевої діяльності в
          майбутньому, яка може стати фатальною<sup>1</sup>.
          <br><br>
          Залежно від природи основного захворювання, яке спричинило розвиток даного загрозливого стану, <strong>призначається
          медикаментозна терапія.</strong>
        </div>
        <div class="section-8__middle--right">
          <strong>Її завдання — </strong>стабілізувати стан, стримати прогресування хвороби та зменшити ризик виникнення
          загрозливих для життя аритмій.
          <br><br>
          Іншим можливим варіантом лікування є <strong>оперативне втручання —</strong> стентування коронарних артерій,
          аорто-коронарне шунтування, протезування клапанів тощо. При шлуночковій тахікардії може знадобитися катетерна
          радіочастотна абляція, яка дає змогу зменшити кількість нападів загрозливих аритмій, але вона не гарантує
          пацієнту 100% успіх<sup>4</sup>.
        </div>
      </div>
      <div class="section-8__banner">
        <div class="section-8__container">
          <div class="section-8__icon-wrapper icon-wrapper rings"></div>
          <div class="section-8__icon-wrapper icon-wrapper rings"></div>
          <div class="section-8__icon-wrapper icon-wrapper rings"></div>
          <svg width="156" height="156" viewBox="0 0 156 156" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_646_17560)">
              <circle cx="78" cy="73.0005" r="62.2329" fill="white"/>
            </g>
            <path
              d="M77.9968 65.9788C79.1969 64.5235 80.3533 62.8728 81.7582 61.4691C85.1711 58.0536 89.2903 56.1325 94.1987 56.5765C101.171 57.2064 106.206 60.9032 109.416 66.9995C112.759 73.3474 112.481 79.7578 109.172 86.0761C106.85 90.5091 103.672 94.2857 100.124 97.7637C93.7471 104.015 86.5478 109.179 78.8844 113.72C78.3171 114.056 77.7921 114.116 77.2029 113.764C67.8128 108.155 59.0211 101.793 51.8578 93.4197C48.6855 89.7119 46.0555 85.6712 44.8132 80.8661C42.0113 70.0414 49.4372 58.5413 60.4619 56.7234C65.8954 55.8277 70.4693 57.7082 74.2807 61.5082C75.6699 62.8947 76.8076 64.5313 77.9968 65.9788ZM46.7009 77.063C46.8681 77.943 47.0416 79.5578 47.4916 81.0912C48.7918 85.5071 51.4155 89.1679 54.4253 92.5553C61.0667 100.024 69.0582 105.822 77.5202 111.019C77.7593 111.166 78.2437 111.169 78.4828 111.022C86.0118 106.417 93.1329 101.274 99.3462 94.9609C102.556 91.6987 105.384 88.1535 107.413 84.0065C111.573 75.5045 108.18 66.4399 101.803 61.8896C96.8099 58.3272 90.8498 58.0583 86.0555 61.1752C82.9989 63.1635 80.8831 65.9616 79.3032 69.202C79.0266 69.7709 78.7016 70.2555 77.9937 70.2555C77.2858 70.2539 77.006 69.7365 76.6794 69.2035C75.7028 67.6107 74.8183 65.9287 73.6306 64.5032C67.7581 57.4596 59.7009 57.6316 53.5783 62.382C49.0731 65.8756 46.9009 70.6385 46.7009 77.063Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M96.7353 36C98.8136 35.9938 100.509 37.6929 100.498 39.7703C100.487 41.8133 98.8152 43.4828 96.7665 43.4968C94.7303 43.5125 93.0208 41.8352 93.0036 39.8031C92.9848 37.7179 94.6647 36.0063 96.7353 36ZM96.7649 38.5042C96.0867 38.4979 95.5179 39.056 95.5101 39.7359C95.5023 40.4128 96.0617 40.9833 96.7399 40.9911C97.4166 40.9989 97.9885 40.4378 97.9948 39.7609C98.0026 39.081 97.4431 38.5104 96.7649 38.5042Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M59.4946 47.725C59.504 49.8009 57.8116 51.4969 55.7286 51.4969C53.694 51.4953 52.0016 49.804 52 47.7703C51.9969 45.6851 53.6908 43.9907 55.7645 44C57.8101 44.0079 59.4853 45.682 59.4946 47.725ZM55.7895 46.5042C55.1129 46.4823 54.53 47.0294 54.5066 47.7062C54.4831 48.3815 55.03 48.9661 55.7083 48.9896C56.3833 49.013 56.9678 48.4644 56.9912 47.7875C57.0147 47.1107 56.4662 46.5261 55.7895 46.5042Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M76.4921 41.7708C76.4921 42.2116 76.5233 42.654 76.4843 43.0916C76.4233 43.7732 75.8905 44.2468 75.2451 44.2452C74.5981 44.2437 74.0481 43.7685 74.0152 43.0854C73.973 42.2069 73.9762 41.3237 74.0121 40.4437C74.0402 39.7512 74.5653 39.2713 75.2123 39.2526C75.8889 39.2322 76.4343 39.7246 76.4874 40.4499C76.5202 40.8876 76.4937 41.33 76.4937 41.7708C76.4937 41.7708 76.4937 41.7708 76.4921 41.7708Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M112.487 44.5188C112.487 44.9596 112.518 45.402 112.479 45.8397C112.418 46.5212 111.885 46.9948 111.24 46.9933C110.593 46.9917 110.043 46.5165 110.01 45.8334C109.968 44.9549 109.971 44.0718 110.007 43.1917C110.035 42.4992 110.56 42.0194 111.207 42.0006C111.884 41.9803 112.429 42.4727 112.482 43.198C112.515 43.6357 112.488 44.078 112.488 44.5188C112.488 44.5188 112.488 44.5188 112.487 44.5188Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M49.4867 30.5188C49.4867 30.9596 49.518 31.402 49.4789 31.8397C49.418 32.5212 48.8851 32.9948 48.2397 32.9933C47.5927 32.9917 47.0427 32.5165 47.0099 31.8334C46.9677 30.9549 46.9708 30.0718 47.0067 29.1917C47.0349 28.4992 47.5599 28.0194 48.2069 28.0006C48.8835 27.9803 49.4289 28.4727 49.482 29.198C49.5148 29.6357 49.4883 30.078 49.4883 30.5188C49.4883 30.5188 49.4883 30.5188 49.4867 30.5188Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M70.488 45.2547C70.9287 45.2547 71.3709 45.2281 71.8085 45.2609C72.4992 45.3125 72.9774 45.8236 72.9946 46.4692C73.0118 47.1445 72.5164 47.7057 71.7913 47.7369C70.9381 47.7744 70.0817 47.7729 69.2285 47.7385C68.4956 47.7088 68.0002 47.168 68.0049 46.4895C68.0096 45.8096 68.5081 45.3016 69.2457 45.2547C69.6582 45.2297 70.0739 45.25 70.488 45.25C70.488 45.2531 70.488 45.2547 70.488 45.2547Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M106.483 48.0027C106.924 48.0027 107.366 47.9761 107.804 48.009C108.494 48.0605 108.972 48.5717 108.99 49.2173C109.007 49.8925 108.511 50.4537 107.786 50.485C106.933 50.5225 106.077 50.5209 105.224 50.4865C104.491 50.4568 103.995 49.916 104 49.2376C104.005 48.5576 104.503 48.0496 105.241 48.0027C105.653 47.9777 106.069 47.998 106.483 47.998C106.483 48.0011 106.483 48.0027 106.483 48.0027Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M43.4831 34.0027C43.9238 34.0027 44.3661 33.9761 44.8036 34.009C45.4943 34.0605 45.9725 34.5717 45.9897 35.2173C46.0069 35.8925 45.5115 36.4537 44.7864 36.485C43.9332 36.5225 43.0768 36.5209 42.2236 36.4865C41.4907 36.4568 40.9953 35.916 41 35.2376C41.0047 34.5576 41.5032 34.0496 42.2408 34.0027C42.6534 33.9777 43.069 33.998 43.4831 33.998C43.4831 34.0011 43.4831 34.0027 43.4831 34.0027Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M79.9969 47.7497C79.5828 47.7497 79.1671 47.77 78.7546 47.745C78.0154 47.6996 77.5154 47.1947 77.506 46.5163C77.4966 45.8364 77.9904 45.2908 78.7218 45.2611C79.575 45.2268 80.4313 45.2268 81.2845 45.2611C82.0128 45.2908 82.5081 45.8442 82.4956 46.521C82.4831 47.1947 81.9784 47.7012 81.2408 47.745C80.8267 47.77 80.411 47.7497 79.9969 47.7497Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M115.992 50.4977C115.578 50.4977 115.162 50.518 114.75 50.493C114.011 50.4477 113.51 49.9428 113.501 49.2644C113.492 48.5844 113.986 48.0389 114.717 48.0092C115.57 47.9748 116.426 47.9748 117.28 48.0092C118.008 48.0389 118.503 48.5922 118.491 49.2691C118.478 49.9428 117.973 50.4493 117.236 50.493C116.822 50.518 116.406 50.4977 115.992 50.4977Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M52.992 36.4977C52.5779 36.4977 52.1622 36.518 51.7497 36.493C51.0105 36.4477 50.5105 35.9428 50.5011 35.2644C50.4917 34.5844 50.9855 34.0389 51.7169 34.0092C52.5701 33.9748 53.4264 33.9748 54.2797 34.0092C55.0079 34.0389 55.5032 34.5922 55.4907 35.2691C55.4782 35.9428 54.9735 36.4493 54.2359 36.493C53.8218 36.518 53.4061 36.4977 52.992 36.4977Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M76.4934 51.2381C76.4934 51.6789 76.5199 52.1213 76.4871 52.559C76.434 53.2483 75.9245 53.7314 75.2807 53.7485C74.6056 53.7673 74.0431 53.2718 74.0118 52.5465C73.9743 51.693 73.9759 50.8364 74.0087 49.9829C74.0368 49.2483 74.5744 48.7527 75.2542 48.7559C75.9324 48.759 76.4449 49.2576 76.4918 49.9954C76.5184 50.4081 76.4965 50.8239 76.4965 51.2381C76.4965 51.2381 76.4949 51.2381 76.4934 51.2381Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M112.488 53.9862C112.488 54.427 112.515 54.8694 112.482 55.307C112.429 55.9964 111.92 56.4794 111.276 56.4966C110.601 56.5154 110.038 56.0198 110.007 55.2945C109.969 54.4411 109.971 53.5845 110.004 52.731C110.032 51.9963 110.57 51.5008 111.249 51.5039C111.927 51.507 112.44 52.0057 112.487 52.7435C112.513 53.1562 112.492 53.572 112.492 53.9862C112.492 53.9862 112.49 53.9862 112.488 53.9862Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M49.4885 39.9862C49.4885 40.427 49.515 40.8694 49.4822 41.307C49.4291 41.9964 48.9197 42.4794 48.2758 42.4966C47.6008 42.5154 47.0382 42.0198 47.0069 41.2945C46.9694 40.4411 46.971 39.5845 47.0038 38.731C47.0319 37.9963 47.5695 37.5008 48.2493 37.5039C48.9275 37.507 49.44 38.0057 49.4869 38.7435C49.5135 39.1562 49.4916 39.572 49.4916 39.9862C49.4916 39.9862 49.49 39.9862 49.4885 39.9862Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M77.5999 100.283C73.8932 100.127 70.6429 98.4249 68.3864 94.9609C68.1551 94.6061 67.9879 94.0434 68.1035 93.6745C68.216 93.3134 68.6833 92.9414 69.0739 92.8148C69.6553 92.6256 70.0944 93.0148 70.4116 93.5197C71.6539 95.5002 73.3994 96.8273 75.6513 97.4463C79.3517 98.4655 83.2084 97.0227 85.3758 93.8464C85.4633 93.7182 85.5462 93.5854 85.6352 93.4572C86.1306 92.7522 86.8213 92.5756 87.4417 92.993C88.0355 93.3916 88.1652 94.0746 87.7402 94.7796C86.6244 96.6288 85.0945 98.0435 83.174 99.0282C81.5504 99.8598 79.8174 100.258 77.5999 100.283Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M50.5079 73.4318C50.6579 70.9136 51.8424 68.7064 53.6145 66.776C54.9991 65.2675 56.6055 64.0389 58.3948 63.051C59.2496 62.5789 59.97 62.7306 60.3419 63.4308C60.6982 64.1014 60.4513 64.7501 59.6262 65.2269C57.4369 66.4946 55.4335 68.0046 54.174 70.2352C53.5911 71.2684 53.277 72.4908 53.0379 73.6679C52.8269 74.7011 52.4956 75.281 51.7081 75.256C50.9095 75.2326 50.472 74.6261 50.5079 73.4318Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M66.7488 76.4956C68.9163 76.7379 70.5602 77.7367 71.5822 79.661C71.9276 80.3128 71.6994 81.01 71.1025 81.346C70.5274 81.6696 69.8117 81.5461 69.4632 80.9318C68.0052 78.3651 65.3768 78.5465 64.0516 80.9052C63.686 81.5555 62.939 81.6821 62.353 81.3242C61.7982 80.9849 61.581 80.3081 61.9061 79.6891C62.9296 77.7274 64.5986 76.7254 66.7488 76.4956Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M89.2873 76.4971C91.4266 76.7409 93.0909 77.7413 94.102 79.7046C94.4223 80.3268 94.2035 80.9958 93.6425 81.3334C93.0784 81.6726 92.4111 81.5335 91.9845 80.977C91.8751 80.8332 91.8001 80.6628 91.6923 80.5175C90.2781 78.6151 88.3091 78.5354 86.8542 80.4612C86.0604 81.5116 85.4869 81.768 84.7868 81.274C84.068 80.7676 84.0805 80.0048 84.8446 78.9528C85.9354 77.4537 87.4277 76.6737 89.2873 76.4971Z"
              fill="#285EFF" stroke="#285EFF"/>
            <path
              d="M52.9596 79.008C52.9596 79.6708 52.4236 80.2069 51.761 80.2069H51.7469C51.0843 80.2069 50.5483 79.6708 50.5483 79.008C50.5483 78.3452 51.0843 77.8091 51.7469 77.8091H51.761C52.422 77.8091 52.9596 78.3452 52.9596 79.008Z"
              fill="#285EFF" stroke="#285EFF"/>
            <defs>
              <filter id="filter0_d_646_17560" x="0.76709" y="0.767578" width="154.466" height="154.466"
                      filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                               result="hardAlpha"/>
                <feOffset dy="5"/>
                <feGaussianBlur stdDeviation="7.5"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_646_17560"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_646_17560" result="shape"/>
              </filter>
            </defs>
          </svg>
        </div>
        <div class="section-8__content">
          <div class="section-8__content--text">
            Саме застосування комбінації зазначених вище методів
            <strong>у поєднанні з імплантацією кардіовертера-дефібрилятора дає змогу</strong>
          </div>
          <div class="section-8__content--subtitle">максимально убезпечити пацієнта, подовжити йому життя та поліпшити
            його якість!<sup>4</sup></div>
        </div>
      </div>
      <div class="section-8__bottom">
        <img src="@/assets/img/risk/ikd.png">
        <div class="section-8__bottom--content">
          <div class="section-8__bottom--subtitle">Імплантація кардіовертера-дефібрилятора —</div>
          <div class="section-8__bottom--text">це сучасний високотехнологічний метод первинної та вторинної профілактики
            РСС, який <strong>забезпечує стимуляцію та дефібриляцію серця у разі виникнення загрозливих для життя
              станів.</strong></div>
          <div class="section-8__bottom--text">Він рекомендований пацієнтам, які тривало отримують адекватну
            фармакотерапію, з очікуваною виживаністю більше одного року<sup>1</sup>.
          </div>
          <router-link to="/" class="main-btn"
                       onclick="gtag('event', 'click', {'event_category': 'Детальніше про ІКД'})">
            <div class="main-btn__text">
              Детальніше<br>про ІКД
            </div>
            <div class="after main-btn__decor">
              <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L8 8L1 15" stroke="#285EFF" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </div>
          </router-link>
        </div>
      </div>
    </section>
    <section class="section-9 anchor" id="check-patient">
      <div class="section-9__banner">
        <div class="section-9__banner--left">
          <div class="section-9__title">Як зрозуміти, що саме цей пацієнт потребує ІКД?</div>
          <div class="section-9__text">Відповідно до міжнародних протоколів лікування, необхідно оцінити дані та
            показники пацієнта для виявлення можливого високого ризику РРС<sup>1,5</sup>.
          </div>
          <div class="section-9__description">З цією метою ми створили алгоритм, який допоможе визначити потребу в
            імплантації ІКД.
          </div>
          <router-link to="/check" class="main-btn"
                       onclick="gtag('event', 'click', {'event_category': 'Оцінити необхідність ІКД'})">
            <div class="main-btn__text" @click="setRestart">
              Оцінити необхідність ІКД
            </div>
            <div class="after main-btn__decor">
              <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L8 8L1 15" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </div>
          </router-link>
        </div>
        <div class="section-9__banner--right">
          <img class="question-top-right" src="@/assets/img/risk/question_top_right.svg" alt="">
          <img class="question-bottom-right" src="@/assets/img/risk/question_bottom_right.svg" alt="">
          <img class="question-top-left" src="@/assets/img/risk/question_top_left.svg" alt="">
          <img class="question-bottom-left" src="@/assets/img/risk/question_bottom_left.svg" alt="">
          <img class="section-9__img" src="@/assets/img/risk/portrait.png">
        </div>
      </div>
    </section>
    <section class="section-10">
      <p class="section-10__text">*Фото моделі, що не є реальним портретом пацієнта, використовується за ліцензією
        Shutterstock</p>
      <div class="section-10__subtitle">Джерела:</div>
      <ul class="section-10__list">
        <li class="section-10__item">
          <p class="section-10__text"><strong>1. </strong> Priori SG et al. ESC Scientific Document Group. 2015 ESC
            Guidelines for the management of patients with ventricular arrhythmias and the prevention of sudden cardiac
            death: The Task Force for the Management of Patients with Ventricular Arrhythmias and the Prevention of
            Sudden Cardiac Death of the European Society of Cardiology (ESC). Endorsed by: Association for European
            Paediatric and Congenital Cardiology (AEPC). Eur Heart J. 2015 Nov 1;36(41):2793-2867.</p>
        </li>
        <li class="section-10__item">
          <p class="section-10__text"><strong>2. </strong>Денесюк В.І., Денесюк О.В. Раптова серцева смерть.
            Етіопатогенез, фактори ризику, стратифікація виникнення та діагностика. Гострі на невідкладні стани у
            практиці лікаря, 2018. 1 (70): 28-33.</p>
        </li>
        <li class="section-10__item">
          <p class="section-10__text"><strong>3. </strong>Adabag AS, Luepker RV, Roger VL, Gersh BJ. Sudden cardiac
            death: epidemiology and risk factors. Nat Rev Cardiol. 2010;7(4):216-225.</p>
        </li>
        <li class="section-10__item">
          <p class="section-10__text"><strong>4. </strong>Профілактика раптової серцевої смерті (РСС). <a
            href="https://scd.com.ua/article/6-profilaktika_raptovoi_sertsevoi_smerti_rss" target="_blank"
            class="section-10__link">https://scd.com.ua/article/6-profilaktika_raptovoi_sertsevoi_smerti_rss .</a>(дата
            звернення: 27.08.2021).</p>
        </li>
        <li class="section-10__item">
          <p class="section-10__text"><strong>5. </strong>Al-Khatib SM. et al. 2017 AHA/ACC/HRS guideline for management
            of patients with ventricular arrhythmias and the prevention of sudden cardiac death: Executive summary: A
            Report of the American College of Cardiology/American Heart Association Task Force on Clinical Practice
            Guidelines and the Heart Rhythm Society. Heart Rhythm. 2018 Oct;15(10):e190-e252.</p>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import TopSection from '../components/TopSection.vue'
import getElementVisible from '@/helper'
import {mapMutations} from 'vuex'

export default {
  name: 'Risk',
  data() {
    return {
      state: '',
      currentState: '',
      trigger25: true,
      trigger50: true,
      trigger75: true,
      trigger90: true,
    }
  },
  components: {
    Header,
    TopSection
  },
  methods: {
    ...mapMutations([
      'setRestart'
    ]),
    changeState(value) {
      this.currentState = value
      localStorage.setItem('riskCurrentState', value)
      if (this.state !== this.currentState) {
        this.state = value
        localStorage.setItem('riskState', value)
      }
      this.scrollToActiveMenu()
    },
    scrollToActiveMenu() {
      var menu = document.querySelector('.bottom-nav')
      var menuItems = document.querySelectorAll('.bottom-list__item')

      menuItems.forEach((item, key) => {
        if (item.hash === window.location.hash) {
          menu.scrollLeft = menuItems[key].offsetLeft
        }
      })
    }
  },

  mounted() {
    if (localStorage.getItem('riskCurrentState') !== null) {
      this.currentState = localStorage.getItem('riskCurrentState')
    }
    if (localStorage.getItem('riskState') !== null) {
      this.state = localStorage.getItem('riskState')
    }
    this.scrollToActiveMenu()

    const headings = document.querySelectorAll('.anchor')
    document.addEventListener('scroll', this.animate)
    window.addEventListener('scroll', () => {

      var h = document.documentElement,
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';

      var percent = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);

      if (percent >= 25 && percent < 50 && this.trigger25) {
        this.trigger25 = false
        gtag('event', 'scroll_view', {'scroll_percent': 'moreThan25%'})
      } else if (percent >= 50 && percent < 75 && this.trigger50) {
        this.trigger50 = false
        gtag('event', 'scroll_view', {'scroll_percent': 'moreThan50%'})
      } else if (percent >= 75 && percent < 90 && this.trigger75) {
        this.trigger75 = false
        gtag('event', 'scroll_view', {'scroll_percent': 'moreThan75%'})
      } else if (percent >= 90 && this.trigger90) {
        this.trigger90 = false
        gtag('event', 'scroll_view', {'scroll_percent': 'moreThan90%'})
      }

      headings.forEach((ha) => {
        const rect = ha.getBoundingClientRect()
        if (rect.top > 0 && rect.top < 100) {
          const location = window.location.toString().split('#')[0]
          history.replaceState(null, null, location + '#' + ha.id)
          this.currentState = ha.id

          if (ha.id === 'main-reason') {
            const listDecor = document.querySelectorAll('.section-3__item')
            listDecor.forEach((e) => {
              e.classList.add('animated')
            })
            const chart = document.querySelector('.section-3__chart')
            chart.classList.add('animated')
          }
        }
      })
      if (getElementVisible('main-reason')) {
        const listDecor = document.querySelectorAll('.section-3__item')
        listDecor.forEach((e) => {
          e.classList.add('animated')
        })
        const chart = document.querySelector('.section-3__chart')
        chart.classList.add('animated')
      }
    })
  }
}
</script>

<style lang="scss" scoped>
$fontR: "Montserrat-Regular";
$fontM: "Montserrat-Medium";
$fontB: "Montserrat-Bold";
$fontSB: "Montserrat-SemiBold";
$fontL: "Montserrat-Light";

.percent {
  font-family: $fontM;
  font-weight: 500;
  font-size: 48px;
  line-height: 100%;

  span {
    font-size: 64px;
  }
}

.main-btn {
  background: #285EFF;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 50px;
  width: 234px;
  //min-width: 234px;
  position: relative;
  z-index: 2;
  transition: all .5s ease-in-out;
  -webkit-tap-highlight-color: transparent !important;

  &.sticky {
    position: sticky;
    // top: 90vh;
    top: 590px;
    right: 20px;
    z-index: 9;

    margin-right: 0;
    margin-left: auto;

    display: none;

    transform: translateY(-90px);

    @media screen and (max-width: 1020px) {
      display: flex;
    }
  }

  &__text {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    font-family: $fontM;
    text-align: left;
    max-width: 141px;
    position: relative;
    z-index: 2;
    transform: translateX(35px);
    transition: all .5s ease-in-out;
  }

  &__decor {
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    background-color: #FFFFFF;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto 0;

    svg {
      top: 6px;
      position: absolute;
      left: 11px;
    }

    &.after {
      transition: all .5s ease-in-out;
    }

  }

  &:hover {
    & > .main-btn__text {
      transform: translateX(60px);
      @media screen and (max-width: 540px) {
        transform: translateX(40px) !important;
      }
    }

    & > .main-btn__decor {
      &.after {
        right: calc(100% - 40px);

        @media screen and (max-width: 540px) {
          right: calc(100% - 29px);
        }
      }
    }
  }

  &:active {
    background-color: #DC1B85;

    svg path {
      stroke: #DC1B85;
    }
  }
}

.risk {
  text-align: left;
  width: 100%;
  position: relative;

  &__text {
    font-size: 24px;
    // line-height: 29px;
  }

  &__main-title {
    font-family: $fontM;
    font-weight: 500;
    font-size: 64px;
    line-height: 78px;
  }

  &__subtitle {
    font-family: $fontM;
    font-weight: 500;
    font-size: 36px;
    line-height: 110%;
  }

  .section-2 {
    padding: 0 13%;
    padding-top: 167px;
    display: grid;
    grid-template-columns: 44% 53%;
    grid-column-gap: 10%;
    max-width: 100%;
    align-items: center;

    &__subtitle {
      margin-bottom: 6px;
    }

    &__container {
      position: absolute;
      bottom: calc(100% - 151px / 2);
      left: calc(50% - 151px / 2);
      border-radius: 50%;
      width: 151px;
      height: 151px;
    }

    &__left {
      border: 1px solid #3C3C3C;
      background: rgba(251, 53, 162, 0.05);
      border-radius: 50px;
      position: relative;
      padding: 95px 49px 43px 34px;
      text-align: left;
    }

    &__item {
      position: relative;
      padding-left: 21px;
      margin-top: 18px;
      font-size: 24px;
      line-height: 29px;

      &::before {
        content: "•";
        color: #3C3C3C;
        font-size: 56px;
        position: absolute;
        left: 0;
        top: -4px;
      }
    }

    .rings {
      position: absolute;
      top: -4px;
      // right: 30px;
      border-radius: 50%;
      border: 4px solid #FB35A2;
      width: 151px;
      height: 151px;
      max-width: 151px;
      max-height: 151px;
      filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.15));
      animation-duration: 2.0s;
      animation-name: pulse_1;
      animation-iteration-count: infinite;

      &:nth-of-type(2) {
        animation-name: pulse_2;
      }

      &:nth-of-type(3) {
        animation-name: pulse_3;
      }
    }
  }

  .section-3 {
    padding-top: 118px;
    padding-bottom: 123px;
    padding-left: 16%;
    position: relative;

    &__title {
      font-weight: 500;
      font-size: 48px;
      line-height: 59px;
      font-family: $fontM;
      max-width: 80%;
      margin-bottom: 45px;
    }

    &__table {
      max-width: 80%;
      display: grid;
      grid-template-columns: repeat(3, 280px);
      grid-column-gap: 30px;
    }

    &__chart {
      position: absolute;
      right: 0;
      top: 28px;
      opacity: 0;

      &.animated {
        animation: pull_up 1s 0.5s 1 alternate forwards;
        animation-delay: 2s;
      }
    }

    &__item {
      &--decor {
        height: 1px;
        background-color: #3C3C3C;
        width: 100%;
        margin-bottom: 22px;
        opacity: 0;
      }

      &--percent {
        opacity: 0;
      }

      &--text {
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        font-family: $fontSB;
        margin-top: 15px;
        opacity: 0;
      }

      &:last-child {
        margin-right: 30px;
      }

      &.animated {
        .section-3__item {
          &--decor {
            animation: pull_up 1s 0.5s 1 alternate forwards;
          }

          &--percent {
            animation: pull_up 1s 0.5s 1 alternate forwards;
            animation-delay: .2s;
          }

          &--text {
            animation: pull_up 1s 0.5s 1 alternate forwards;
            animation-delay: .4s;
          }
        }
      }

      &:nth-of-type(2) {
        &.animated {
          .section-3__item {
            &--decor {
              animation: pull_up 1s 0.5s 1 alternate forwards;
              animation-delay: .6s;
            }

            &--percent {
              animation: pull_up 1s 0.5s 1 alternate forwards;
              animation-delay: .8s;
            }

            &--text {
              animation: pull_up 1s 0.5s 1 alternate forwards;
              animation-delay: 1s;
            }
          }
        }
      }

      &:nth-of-type(3) {
        &.animated {
          .section-3__item {
            &--decor {
              animation: pull_up 1s 0.5s 1 alternate forwards;
              animation-delay: 1.2s;
            }

            &--percent {
              animation: pull_up 1s 0.5s 1 alternate forwards;
              animation-delay: 1.4s;
            }

            &--text {
              animation: pull_up 1s 0.5s 1 alternate forwards;
              animation-delay: 1.6s;
            }
          }
        }
      }
    }
  }

  .section-4 {
    background-color: rgba(251, 53, 162, .15);
    padding: 38px 16% 67px 16%;
    display: grid;
    grid-template-columns: 40% 52%;
    grid-column-gap: 10%;
    align-items: end;

    &__img {
      display: block;
      opacity: .38;
    }

    &__left {
      &--top {
        position: relative;
        display: flex;
      }

      &--bottom {
        margin-top: 24px;
        font-family: $fontM;
        font-weight: 500;
        font-size: 36px;
      }
    }

    &__description {
      font-family: $fontM;
      font-weight: 500;
      font-size: 30px;
      line-height: 37px;
    }

    &__item {
      position: relative;
      padding-left: 21px;
      margin-top: 19px;
      font-size: 24px;


      &::before {
        content: "";
        color: #3C3C3C;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 7px;
        height: 7px;
        background: #3C3C3C;
        margin: auto 0;
        border-radius: 50%;

        @media screen and (max-width: 425px) {

          width: 5px;
          height: 5px;
        }
      }
    }

    &__container {
      position: relative;
      align-self: flex-end;
      margin-bottom: 6px;
      border-radius: 50%;
      width: 151px;
      height: 151px;
    }

    .rings {
      position: absolute;
      top: -4px;
      // right: 30px;
      border-radius: 50%;
      border: 4px solid #FB35A2;
      width: 151px;
      height: 151px;
      max-width: 151px;
      max-height: 151px;
      filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.15));
      animation-duration: 2.0s;
      animation-name: pulse_1;
      animation-iteration-count: infinite;

      &:nth-of-type(2) {
        animation-name: pulse_2;
      }

      &:nth-of-type(3) {
        animation-name: pulse_3;
      }
    }
  }

  .section-5 {
    padding: 120px 16%;
    display: grid;
    grid-template-columns: 45% 53%;
    grid-column-gap: 10%;
    max-width: 100%;

    &__left {
      border: 1px solid #3C3C3C;
      background: rgba(251, 53, 162, 0.05);
      border-radius: 50px;
      position: relative;
      padding: 28px 48px 60px 49px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 550px;

      img {
        align-self: flex-end;
        transform: translateY(28px);
        max-width: 178px
      }

      // .section-5__text {
      //   margin-top: 11px;
      // }
    }

    &__list {
      margin-top: 15px;
    }

    &__item {
      display: flex;
      align-items: center;
      margin-top: 15px;

      &--img-wrapper {
        background: #FFFFFF;
        border-radius: 50%;
        border: 3px solid #FB35A2;
        width: 103px;
        height: 103px;
        min-width: 103px;
        min-height: 103px;
        position: relative;
        margin-right: 20px;

        & > img {
          position: absolute;
          left: -9px;
          top: -4px;
        }
      }
    }
  }

  .section-6 {
    background-color: rgba(251, 53, 162, .15);
    background-image: url('../assets/img/heart-banner.svg');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: 92px;
    padding: 86px 16% 100px 16%;

    &__title {
      opacity: .86;
      margin-bottom: 35px;
    }

    &__description {
      font-size: 18px;
      width: 74%;
      margin-bottom: 46px;
    }

    &__subtitle {
      font-family: $fontM;
      font-weight: 500;
      font-size: 48px;
      line-height: 59px;
      margin-bottom: 50px;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 70px;
    }

    &__item {
      border: 1px solid #3C3C3C;
      border-radius: 50px;
      background-color: #FFFFFF;
      padding-left: 48px;
      padding-right: 32px;
      min-height: 127px;
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .section-7 {
    padding: 106px 16% 115px 16%;

    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 104px;
      grid-row-gap: 25px;
      margin-top: 35px;
    }

    &__item {
      display: flex;
      align-items: center;
      max-width: 414px;

      &--img-wrapper {
        background: #FFFFFF;
        border-radius: 50%;
        border: 3px solid #285EFF;
        width: 104px;
        height: 104px;
        min-width: 104px;
        min-height: 104px;
        position: relative;
        margin-right: 21px;

        & > img {
          position: absolute;
          left: -9px;
          top: -4px;
        }
      }

      &:nth-of-type(3),
      &:nth-of-type(5) {
        .section-7__item--text {
          width: 60%;
        }
      }

      &:nth-of-type(7) {
        .section-7__item--text {
          width: 53%;
        }
      }
    }

    &__description {
      font-family: $fontSB;
      font-weight: 600;
      font-size: 24px;
    }
  }

  .section-8 {
    padding: 91px 16% 95px 16%;
    background-image: url('../assets/img/risk/icon_siren.svg');
    background-repeat: no-repeat;
    background-position-y: 36px;
    background-position-x: 84%;
    background-color: rgba(40, 94, 255, 0.15);

    &__subtitle {
      font-family: $fontM;
      font-weight: 500;
      font-size: 48px;
      width: 80%;
    }

    &__description {
      font-family: $fontSB;
      font-weight: 600;
      font-size: 24px;
      width: 80%;
      margin-top: 47px;
      margin-bottom: 61px;
    }

    &__middle {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 2%;
      margin-bottom: 102px;

      &--left,
      &--right {
        font-size: 18px;
        line-height: 22px;

        strong {
          font-weight: 600;
          font-family: $fontSB;
        }
      }
    }

    &__banner {
      background: #285EFF;
      box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.15);
      border-radius: 50px;
      padding: 47px 53px 44px 69px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 110px;
    }

    &__container {
      position: relative;
      // align-self: flex-end;
      margin-bottom: 6px;
      border-radius: 50%;
      width: 154px;
      height: 154px;
    }

    .rings {
      position: absolute;
      top: -4px;
      border-radius: 50%;
      border: 4px solid #FFFFFF;
      width: 154px;
      height: 154px;
      max-width: 154px;
      max-height: 154px;
      filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.15));
      animation-duration: 2.0s;
      animation-name: pulse_1;
      animation-iteration-count: infinite;

      &:nth-of-type(2) {
        animation-name: pulse_2;
      }

      &:nth-of-type(3) {
        animation-name: pulse_3;
      }
    }

    &__content {
      margin-left: 48px;
      color: #FFFFFF;

      &--text {
        font-size: 24px;
      }

      &--subtitle {
        font-family: $fontSB;
        font-weight: 600;
        font-size: 30px;
        margin-top: 13px;
      }
    }

    &__bottom {
      display: grid;
      grid-template-columns: 44% 50%;
      grid-column-gap: 6%;

      img {
        object-fit: contain;
        max-width: 100%;
      }

      &--subtitle {
        font-family: $fontM;
        font-weight: 500;
        font-size: 44px;
        margin-bottom: 28px;
      }

      &--text {
        font-size: 18px;
        margin-top: 16px;
      }

      &--content {
        .main-btn {
          margin-top: 46px;
        }
      }
    }
  }

  .section-9 {
    padding: 88px 5% 0 5%;
    color: #FFFFFF;

    &__title {
      font-family: $fontM;
      font-weight: 500;
      font-size: 48px;
      margin-top: 40px;
      margin-bottom: 29px;
      width: 80%;
    }

    &__img {
      display: block;
      height: auto;
      max-width: 380px;
      object-fit: contain;
    }

    &__text {
      font-weight: 300;
      font-size: 18px;
      font-family: $fontL;
      margin-bottom: 7px;
      width: 80%;
    }

    &__description {
      font-family: $fontSB;
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 45px;
      width: 85%;
    }

    &__banner {
      background: #285EFF;
      box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.15);
      border-radius: 50px;
      padding: 20px 11% 0 107px;
      display: flex;
      justify-content: space-between;

      &--left {
        & > .main-btn {
          background: #FFFFFF;
          margin-bottom: 65px;

          &:active {
            background: #DFE7FF;
          }

          & > .main-btn__text {
            color: #285EFF;
          }

          & > .main-btn__decor {
            background-color: #285EFF;
          }
        }
      }

      &--right {
        position: relative;
        display: flex;
        align-items: flex-end;

        .question-top-right {
          position: absolute;
          right: 0;
          top: 103px;
          opacity: 0.5;
          filter: blur(4px);
          animation: question-1 3.5s ease .01s infinite;
        }

        .question-bottom-right {
          position: absolute;
          right: -20%;
          bottom: 38%;
          opacity: 0.75;
          animation: question-2 4s ease .01s infinite;
        }

        .question-top-left {
          position: absolute;
          left: -20px;
          top: 40px;
          opacity: 0.9;
          animation: question-3 3s ease .01s infinite;
        }

        .question-bottom-left {
          position: absolute;
          left: -28px;
          bottom: 39%;
          opacity: 0.25;
          filter: blur(4px);
        }
      }
    }
  }

  .section-10 {
    text-align: left;
    padding: 55px 75px 56px 75px;

    &__subtitle {
      margin-top: 12px;
      font-family: $fontB;
      font-weight: 700;
    }

    &__text {
      font-size: 13px;
      line-height: 16px;
    }

    &__link {
      color: #FB35A2;

      cursor: pointer;
      transition: color .1s ease;

      &:hover {
        color: #D32183;
      }

      &:visited {
        color: #8C23DE;
      }

      &:active {
        color: #FB35A2;
      }
    }

  }
}

@media (max-width: 1200px) {
  .percent {
    font-size: 36px;

    span {
      font-size: 56px;
    }
  }
  .risk {
    &__subtitle {
      font-size: 32px;
    }

    &__text {
      font-size: 20px;
    }

    &__main-title {
      font-size: 56px;
      line-height: 64px;
    }

    .section-2 {
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 80px;
      grid-column-gap: 40px;
    }

    .section-3 {
      padding-left: 5%;

      &__title {
        font-size: 36px;
        line-height: 110%;
        max-width: 75%;
      }

      &__table {
        grid-template-columns: repeat(3, 220px);
        grid-column-gap: 20px;
      }

      &__item {
        &--text {
          font-size: 22px;
          line-height: 28px;
        }
      }
    }

    .section-4 {
      padding: 38px 5% 67px 5%;
      grid-column-gap: 40px;

      &__left {
        &--bottom {
          font-size: 32px;
        }
      }

      &__description {
        font-size: 28px;
        line-height: 34px;
      }

      &__item {
        margin-top: 14px;
      }
    }

    .section-5 {
      padding: 90px 5%;
      grid-column-gap: 40px;

      &__left {
        padding: 14px 24px 60px 25px;
        height: 480px;
      }

      &__item {
        margin-top: 8px;
      }
    }

    .section-6 {
      padding: 86px 5% 100px 5%;

      &__description {
        margin-bottom: 32px;
      }

      &__subtitle {
        font-size: 36px;
        line-height: 110%;
      }

      &__list {
        grid-row-gap: 48px;
      }
    }

    .section-7 {
      padding: 90px 5%;

      &__list {
        grid-column-gap: 86px;
      }
    }

    .section-8 {
      padding: 60px 5% 64px 5%;

      &__middle {
        margin-bottom: 90px;
      }

      &__description {
        margin-top: 36px;
        margin-bottom: 48px;
      }

      &__subtitle,
      &__description {
        width: 70%;
      }

      &__content {
        &--text {
          font-size: 20px;
        }

        &--subtitle {
          font-size: 24px;
        }
      }

      &__bottom {
        &--subtitle {
          font-size: 36px;
          margin-bottom: 22px;
        }
      }
    }

    .section-9 {
      &__title {
        font-size: 36px;
        margin-bottom: 20px;
      }

      &__banner {
        padding: 20px 6% 0 36px;
        overflow: hidden;
      }

      &__description {
        font-size: 20px;
        margin-bottom: 36px;
      }
    }
  }
}

@media (max-width: 940px) {
  .main-btn {
    &__text {
      font-size: 14px;
    }
  }
  .risk {
    .main-btn.sticky {
      transform: translateY(-80px);
    }

    .section-3 {
      &__table {
        grid-template-columns: 1fr;
        max-width: 50%;
        grid-row-gap: 20px;
      }

      &__item {
        &:first-of-type {
          .section-3__item--decor {
            display: none;
          }
        }
      }

      &__title {
        max-width: 100%;
      }

      &__chart {
        top: 30%;
      }
    }

    .section-4 {
      grid-template-columns: 1fr;
      grid-row-gap: 38px;

      &__left {
        &--top {
          justify-content: space-evenly;
        }
      }
    }

    .section-9 {
      &__img {
        height: auto;
        width: 100%;
      }

      &__banner {
        flex-direction: column;
        align-items: center;

        &--left > .main-btn {
          margin-left: auto;
          margin-right: auto;
        }
      }

      &__title,
      &__text,
      &__description {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .risk {
    .section-2,
    .section-5,
    .section-8__middle {
      grid-template-columns: 1fr;
      grid-row-gap: 30px;
    }

    .section-5 {
      &__left {
        height: 360px;
        padding: 21px 47px 35px 21px;
        justify-content: flex-start;

        img {
          transform: translateY(20px);
        }

        .section-5__text {
          transform: translateY(-60%);
          width: 70%;
        }
      }

      &__percent {
        transform: translateY(-140%);
        width: 60%;
      }
    }

    .section-6 {
      &__list {
        grid-template-columns: 1fr;
        grid-row-gap: 15px;
      }
    }

    .section-7 {
      &__list {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }

      &__item {
        max-width: unset;
      }
    }

    .section-8 {
      background-position-x: 90%;
      background-size: 20%;

      &__subtitle {
        font-size: 36px;
      }

      &__banner {
        flex-direction: column;
      }

      &__content {
        margin-left: unset;
        margin-top: 18px;
      }

      &__bottom {
        grid-template-columns: 1fr;

        img {
          max-width: 80%;
          margin: 0 auto;
        }

        &--content {
          margin-top: 36px;

          & > .main-btn {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }

    }

    .section-10 {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}

@media screen and (max-width: 540px) {
  .percent {
    font-size: 24px;

    span {
      font-size: 36px;
    }
  }
  .main-btn {
    width: 150px;
    height: 32px;
    min-height: 32px;

    &__text {
      font-size: 10px;
      line-height: 110%;
      transform: translateX(22px);
      max-width: 89px;
    }

    &__decor {
      width: 19px;
      height: 19px;

      &.after {
      }

      svg {
        top: 1px;
        left: 7px;
        max-width: 5px;
      }
    }
  }
  .risk {
    &__subtitle {
      font-size: 24px;
    }

    &__main-title {
      font-size: 24px;
      line-height: 29px;
    }

    &__text {
      font-size: 16px;
    }

    .section-2 {
      &__container {
        bottom: calc(100% - 115px / 2);
        left: calc(50% - 115px / 2);
        width: 115px;
        height: 115px;

        svg {
          max-width: 115px;
          height: 115px;
        }
      }

      .rings {
        width: 115px;
        height: 115px;
        max-width: 115px;
        max-height: 115px;
      }

      &__left {
        padding: 92px 28px 30px 22px;
      }

      &__right {
        .risk__subtitle {
          margin-bottom: 15px;
        }
      }

      &__subtitle {
        width: 80%;
      }

      &__item {
        padding-left: 18px;
        font-size: 16px;
        line-height: 20px;
        margin-top: 4px;

        &::before {
          font-size: 36px;
          top: -2px;
        }
      }
    }

    .section-3 {
      padding-top: 56px;
      padding-bottom: 64px;

      &__title {
        font-size: 24px;
        margin-bottom: 9px;
      }

      &__item {
        position: relative;
        z-index: 1;

        &--text {
          font-size: 16px;
          line-height: 20px;
          margin-top: 4px;
        }

        &--decor {
          width: 80%;
        }
      }

      &__chart {
        max-width: 260px;
        top: 20%;
      }
    }

    .section-4 {
      padding: 34px 5% 41px 5%;

      &__left {
        &--bottom {
          font-size: 24px;
          margin-top: 34px;
        }
      }

      &__description {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 20px;
      }

      &__item {
        font-size: 16px;
        line-height: 20px;
        padding-left: 19px;


      }
    }

    .section-5 {
      padding: 41px 5% 56px 5%;

      &__left {
        height: 260px;
        padding: 20px 48px 35px 20px;
        border-radius: 30px;

        img {
          max-width: 93px;
          transform: translateY(0);
        }

        .section-5__text {
          transform: translateY(-45%);
          width: 90%;
        }
      }

      &__percent {
        transform: translateY(-90%);
      }

      &__item {
        &--img-wrapper {
          width: 70px;
          height: 70px;
          min-width: 70px;
          min-height: 70px;

          img {
            width: 70px;
            height: 70px;
            left: -3px;
            top: 0;
          }
        }
      }
    }

    .section-6 {
      padding: 41px 5% 45px 5%;
      background-size: 230px;
      background-position-y: 148px;

      &__title {
        margin-bottom: 20px;
      }

      &__subtitle {
        font-size: 24px;
        margin-bottom: 21px;
      }

      &__description {
        width: 100%;
      }

      &__item {
        border-radius: 30px;
        padding: 20px;
        min-height: unset;
      }
    }

    .section-7 {
      padding: 40px 5% 53px 5%;

      &__description {
        font-size: 16px;
      }

      &__item {
        &--img-wrapper {
          width: 68px;
          height: 68px;
          min-width: 68px;
          min-height: 68px;

          img {
            width: 68px;
            height: 68px;
            left: -3px;
            top: 0;
          }
        }
      }
    }

    .section-8 {
      padding: 30px 5% 56px 5%;
      background-position-y: 30px;

      &__subtitle {
        font-size: 24px;
      }

      &__description {
        font-size: 16px;
        margin-bottom: 30px;
        width: 100%;
      }

      &__middle {
        grid-row-gap: 27px;
        margin-bottom: 37px;

        &--left,
        &--right {
          font-size: 15px;
          line-height: 18px;
        }
      }

      &__banner {
        padding: 22px 14px 37px 22px;
        margin-bottom: 49px;
      }

      &__container {
        width: 119px;
        height: 119px;

        .rings {
          width: 119px;
          height: 119px;
          max-width: 119px;
          max-height: 119px;
        }

        svg {
          width: 119px;
          height: 119px;
        }
      }

      &__content {
        &--text,
        &--subtitle {
          font-size: 16px;
          line-height: 20px;
        }
      }

      &__bottom {
        &--subtitle {
          font-size: 24px;
          margin-bottom: 15px;
        }

        &--text {
          font-size: 16px;
        }

        &--content {
          & > .main-btn {
            margin-top: 28px;
          }
        }
      }
    }

    .section-9 {
      padding: 44px 5% 0 5%;

      &__banner {
        padding: 32px 20px 0 20px;

        &--right {
          .question-top-right {
            max-width: 28px;
            top: 56px;
          }

          .question-bottom-right {
            max-width: 45px;
          }

          .question-top-left {
            max-width: 54px;
            top: 20px;
          }

          .question-bottom-left {
            max-width: 33px;
            bottom: 29%;
          }
        }
      }

      &__title {
        font-size: 24px;
        margin-top: 0;
        margin-bottom: 20px;
        width: 90%;
      }

      &__text {
        font-size: 16px;
        margin-bottom: 20px;
      }

      &__description {
        font-size: 16px;
        margin-bottom: 30px;
      }

      &__img {
        max-width: 210px;
        height: unset;
      }
    }

    .section-10 {
      padding: 40px 5%;
    }
  }
}

@keyframes question-1 {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes question-2 {
  0% {
    opacity: 0.75;
    filter: blur(2px);
  }
  50% {
    opacity: 1;
    filter: blur(0px);
  }
  100% {
    opacity: 0.4;
    filter: blur(2px);
  }
}

@keyframes question-3 {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}

@keyframes question-4 {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}

@keyframes pull_up {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes pulse_1 {
  from {
    opacity: 1;
    transform: scale(0.8, 0.8);
  }
  to {
    opacity: 0;
    transform: scale(1.2, 1.2);
  }
}

@keyframes pulse_2 {
  from {
    opacity: 1;
    transform: scale(0.9, 0.9);
  }
  to {
    opacity: 0;
    transform: scale(1.3, 1.3);
  }
}

@keyframes pulse_3 {
  from {
    opacity: 1;
    transform: scale(1, 1);
  }

  to {
    opacity: 0;
    transform: scale(1.4, 1.4);
  }
}
</style>
