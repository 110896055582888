<template>
  <div>
    <section v-if="currentPage === 'implantation'" class="section section-1 anchor" id="ikd-1">
      <div class="section-1__left">
        <h1 v-if="currentPage === 'implantation'" class="section-1__left--title">Імплантований<br> кардіовертер-<br><nobr>дефібрилятор (ІКД) –</nobr></h1>
        <h1 v-if="currentPage === 'risk'" class="section-1__left--title risk">Оцінка ризику<br> раптової серцевої смерті</h1>
        <ul v-if="currentPage === 'implantation'" class="section-1__left--list">
          <li>актуальність</li>
          <li>принцип роботи</li>
          <li>будова</li>
          <li>показання до застосування</li>
        </ul>
        <ul v-if="currentPage === 'risk'" class="section-1__left--list">
          <li>Вивчивши дані про поточний стан пацієнта, можна оцінити ризик РСС та провести його корекцію відповідно до протоколів лікування<sup>1-5</sup></li>
        </ul>
      </div>
      <div class="section-1__right">
        <video preload="auto" v-if="currentPage === 'implantation'" src="@/assets/video/banner-impl.mp4" playsinline autoplay muted></video>
        <video preload="auto" v-if="currentPage === 'risk'" src="@/assets/video/banner-risk.mp4" playsinline autoplay muted></video>
      </div>
    </section>
    <section v-if="currentPage === 'risk'" class="section section-1 anchor" id="rrc">
      <div class="section-1__left">
        <h1 class="section-1__left--title risk">Оцінка ризику<br> раптової серцевої смерті</h1>
        <ul class="section-1__left--list">
          <li>Вивчивши дані про поточний стан пацієнта, можна оцінити ризик РСС та провести його корекцію відповідно до протоколів лікування<sup>1-5</sup></li>
        </ul>
      </div>
      <div class="section-1__right">
        <video preload="auto" src="@/assets/video/banner-risk.mp4" autoplay muted playsinline></video>
      </div>
    </section>
    <section class="section-1-dop">
      <a class="learn-more-btn" href="#banner" @click.prevent="scrollToBanner()">
        <svg v-if="currentPage === 'implantation'" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_i_631_17548)">
          <path d="M0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15Z" fill="#285EFF"/>
          </g>
          <path d="M22 13L15 20L8 13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <defs>
          <filter id="filter0_i_631_17548" x="0" y="0" width="30" height="35" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="5"/>
          <feGaussianBlur stdDeviation="7.5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_631_17548"/>
          </filter>
          </defs>
        </svg>
        <svg v-if="currentPage === 'risk'" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_i_631_17548)">
          <path d="M0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15Z" fill="#FB35A2"/>
          </g>
          <path d="M22 13L15 20L8 13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <defs>
          <filter id="filter0_i_631_17548" x="0" y="0" width="30" height="35" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="5"/>
          <feGaussianBlur stdDeviation="7.5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_631_17548"/>
          </filter>
          </defs>
        </svg>
        Дізнатись більше
      </a>
    </section>
  </div>
</template>

<script>

export default {
  name: 'TopSection',
  props: {
    currentPage: {
      type: String,
      required: true
    }
  },
  methods: {
    scrollToBanner () {
      const learnMoreBtn = document.querySelector('.learn-more-btn')
      const href = learnMoreBtn.getAttribute('href').substring(1)
      const scrollTarget = document.getElementById(href)
      const topOffset = document.querySelector('.header').offsetHeight
      const elementPosition = scrollTarget.getBoundingClientRect().top
      const offsetPosition = elementPosition - topOffset
      window.scrollBy({
        top: offsetPosition,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$fontR: "Montserrat-Regular";
$fontM: "Montserrat-Medium";
$fontB: "Montserrat-Bold";
$fontSB: "Montserrat-SemiBold";

.main-btn {
  background: #285EFF;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 50px;
  width: 234px;
  //min-width: 234px;
  position: relative;
  z-index: 2;
  transition: all .5s ease-in-out;
  -webkit-tap-highlight-color: transparent !important;


  &__text {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
    font-family: $fontM;
    text-align: left;
    max-width: 141px;
    position: relative;
    z-index: 2;
    transform: translateX(35px);
    transition: all .5s ease-in-out;
  }

  &__decor {
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    background-color: #FFFFFF;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto 0;

    svg {
      top: 6px;
      position: absolute;
      left: 11px;
    }

    &.after {
      transition: all .5s ease-in-out;
    }

  }

  &:hover {
    & > .main-btn__text {
      transform: translateX(60px);
      @media screen and (max-width: 540px) {
        transform: translateX(40px) !important;
      }
    }

    & > .main-btn__decor {
      &.after {
        right: calc(100% - 40px);

        @media screen and (max-width: 540px) {
          right: calc(100% - 29px);
        }
      }
    }
  }
  &:active {
    background-color: #1646D6;
  }
}

.risk {
  .section-1-dop {
    margin-bottom: 46px;
  }
}

.section-1 {
  display: flex;
  justify-content: space-between;
  padding: 0 12% 0 13%;
  margin-top: 200px;

  &-dop {
    margin: 50px 5% 70px 5%;

    .learn-more-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 186px;
      font-size: 16px;
      cursor: pointer;
      text-decoration: none;
      color: #3C3C3C;

      -webkit-tap-highlight-color: transparent;

      svg {
        margin-right: 10px;
        transition: transform 0.3s ease;
      }
      @media (hover: hover) {
        &:hover {
          svg {
            transform: translateY(-10px);
          }
        }
      }

    }
    .main-btn {
      display: none;
    }
  }
  &__left {
    margin-top: 52px;
    position: relative;
    z-index: 1;
    &--title {
      text-align: left;
      font-family: $fontM;
      font-weight: 500;
      font-size: 55px;
      line-height: 110%;

      color: #285EFF;

      margin-bottom: 14px;

      &.risk {
        color: #FB35A2;
      }
    }
    &--list {
      li {
        text-align: left;
        font-size: 24px;
        line-height: 29px;

        margin-bottom: 6px;
      }
    }
  }
  &__right {
    position: relative;
    background: #FFFFFF;
    video {
      display: block;
      position: relative;
      right: 66px;
      outline: none;
      border: none;
      // z-index: 2;
      clip-path: inset(1px 1px);
      -webkit-tap-highlight-color: rgba(0,0,0,0);

      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
    }
  }
  &#rrc {
    padding-right: 6%;

    .section-1__left {
      min-width: 480px;
      width: 486px;
      &--list {
        padding-right: 50px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .section-1 {
    // padding-top: 140px;
    margin-top: 140px;
    padding-left: 74px;
    padding-right: 74px;

    &__left {
      &--title {
        font-size: 48px;

        &.risk {
          width: 80%;
        }
      }
      &--list li {
        font-size: 20px;
        line-height: 25px;
      }
    }

    &__right {
      align-self: flex-end;
      video {
        min-width: 380px;
        max-width: 100%;
        height: auto;
        display: block;
        clip-path: inset(1px 1px);

        -webkit-tap-highlight-color: rgba(0,0,0,0);

        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;

        outline: none;
        border: none;
      }
    }
  }
}
@media screen and (max-width: 1020px) {
  .section-1 {
    &-dop {
      display: flex;
      justify-content: space-between;
      .main-btn {
        display: flex;
        position: -webkit-sticky;
        position: sticky;
        right: 0;
        top: 10vh;
        z-index: 11;
      }
    }
    &#rrc {
      & ~ .section-1-dop {
        .main-btn__decor svg {
          left: 11px;
        }
      }
    }
  }
}
@media screen and (max-width: 940px) {
  .main-btn {
    &__text {
      font-size: 14px;
    }
  }
  .section-1 {
    flex-direction: column-reverse;
    // padding-top: 118px;
    margin-top: 118px;

    &-dop {
      .learn-more-btn {
        &:active {
          svg {
            transform: translateY(-10px);
          }
        }
      }
    }

    &__right {
      transform: translateX(0);
      align-self: center;

      video {
        right: 0;
        display: block;
        clip-path: inset(1px 1px);
        -webkit-tap-highlight-color: rgba(0,0,0,0);

        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;

        outline: none;
        border: none;
      }
    }
    &__left {
      margin-top: 30px;
    }
    &-dop {
      margin-top: 40px;
    }

    &#rrc {
      .section-1__right {
        transform: translateX(0);
      }
      .section-1__left {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .section-1 {
    flex-direction: column-reverse;
    &__left {
      &--title {
        font-size: 36px;
      }
      &--list li {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
@media screen and (max-width: 540px) {
  .main-btn {
    width: 150px;
    height: 32px;
    min-height: 32px;
    &__text {
      font-size: 10px;
      line-height: 110%;
      transform: translateX(22px);
      max-width: 89px;
    }
    &__decor {
      width: 19px;
      height: 19px;
      &.after {
        //transform: translateX(36px);
      }
      svg {
        top: 1px;
        left: 1px;
        max-width: 6px;
      }
    }

    &:hover,
    &:active {
      & > .main-btn__decor.after {
        transform: translateX(-66px);
      }
      & > .main-btn__text {
        transform: translateX(50px);
      }
    }
  }
  .section-1 {
    flex-direction: column-reverse;
    padding-left: 20px;
    padding-right: 20px;
    &__left {
      &--title {
        font-size: 27px;
      }
      &--list li {
        font-size: 14px;
        line-height: 17px;
      }
    }
    &__right {
      video {
        clip-path: inset(1px 1px);
        max-width: 220px;
        min-width: 220px;
        height: auto;
        display: block;
        outline: none;
        border: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);

        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
      }
    }
    &-dop {
      margin: 30px 20px 68px 20px;
      & > .learn-more-btn {
        width: 129px;
        white-space: nowrap;
        font-size: 11px;
        line-height: 13px;
        svg {
          margin-right: 8px;
        }
      }
    }
    &#rrc {
      .section-1__right {
        video {
          clip-path: inset(1px 1px);
          max-width: 100%;
          min-width: 320px;
          height: auto;
          display: block;
          margin-top: 10px;
          outline: none;
          border: none;
          -webkit-tap-highlight-color: rgba(0,0,0,0);

          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
        }
      }
      .section-1 {
        &__left {
          margin-top: 0;
          min-width: unset;
        }
      }
      & ~ .section-1-dop {
        .main-btn__decor svg {
          left: 6px;
        }
      }
    }
  }
}
</style>
